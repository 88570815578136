
import get from 'lodash/get'
import identity from 'lodash/identity'
import querystring from 'querystring'
import slugify from 'slugify'
import _html2text from '../html2text'

export const html2text = _html2text

export const debounce = (func, wait, immediate) => {
  var timeout
  return function () {
    var context = this; var args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const excerpt = (text, maxlen) => {
  const str = text || ''
  if (str.length > maxlen) return str.slice(0, maxlen).trimRight() + '...'
  else return str
}

export const arrayEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false
  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }
  return true
}

export const onEnterDo = (input, func) => {
  input.addEventListener('keydown', function (e) {
    if (e.key === 'Enter') func(e)
  })
}

export const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const validURL = value => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
}

export const clearChildren = parent => {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild)
  }
  return parent
}

export const cloneWithoutChildren = parent => {
  var cNode = parent.cloneNode(false)
  parent.parentNode.replaceChild(cNode, parent)
  return cNode
}

export const byteSizeFormat = (bytes, size) => {
  var names = size === 'long'
    ? ['bytes', 'kilo bytes', 'mega bytes', 'giga bytes', 'terra bytes']
    : ['bytes', 'kb', 'mb', 'gb', 'tb']
  if (bytes <= 0) {
    return '0 ' + names[0]
  }
  var index = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1024)),
    names.length
  )
  var units = Math.round(bytes / Math.pow(1024, index))
  return units + ' ' + names[index]
}

export const strRepeat = (str, n) => {
  if (n === 0) return ''
  n = n || 1
  return Array(n + 1).join(str)
}

export const logError = err => {
  console.error(err)
  window.setTimeout(() => { throw err }, 0)
}

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  var results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index
}

export const removeInPlace = (array, value) => {
  let i = array.indexOf(value)
  while (i >= 0) {
    array.splice(i, 1)
    i = array.indexOf(value, i)
  }
  return array
}

export const throttle = (func, wait, immediate) => {
  let timeout
  return function () {
    const context = this
    const args = arguments

    const later = function later () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}
export const deviceReady = new Promise((resolve, reject) => {
  if (window.cordova) {
    // The deviceready event behaves somewhat differently from others.
    // Any event handler registered after the deviceready event fires has its callback function called immediately.
    document.addEventListener('deviceready', resolve)
  } else if (window.DOMContentLoaded) {
    resolve()
  } else {
    window.addEventListener('DOMContentLoaded', resolve)
  }
})

export const coerce = (value, type = undefined) => {
  switch (type) {
    case 'bool':
    case 'boolean':
    case Boolean:
      return !!value

    case 'integer':
      return parseInt(value)

    case 'float':
      return parseFloat(value)

    case 'number':
    case Number:
      return +value

    case 'string':
    case String:
      return '' + value

    case 'array':
    case Array:
      try {
        return Array.from(value)
      } catch (err) {
        return Array(value)
      }

    case 'object':
    case Object:
      return Object(value)

    case 'null':
    case null:
      return null

    case 'undefined':
    case undefined:
      return undefined

    default:
      return value
  }
}

export const slugifyURIComponent = str => {
  return slugify(str, {
    replacement: '-', // replace spaces with replacement
    lower: true // result in lower case
  })
}

const throttledScrollEvent = throttle(({ target, callback }) => {
  const { scrollTop, scrollHeight } = target
  var value = scrollTop
  var scrollBottom = scrollHeight - scrollTop

  // respect bounds of element
  var percent = (value) / scrollHeight
  percent = Math.min(1, Math.max(percent, 0)) * 100

  const info = {
    target,
    scrollTop,
    scrollBottom,
    percent
  }

  callback(info)
}, 200, false)

export const scrollEvent = callback => e => {
  const { target } = e

  throttledScrollEvent({ target, callback })
}

export const timeout = ms => new Promise(resolve => setTimeout(resolve, ms))

export const filterUnique = (selectionCb) => (value, index, self) => {
  value = selectionCb(value)
  const item = self.find(item => selectionCb(item) === value)
  return self.indexOf(item) === index
}

export const requestIdleCallback = cb => {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(cb, { timeout: 1000 })
  } else {
    window.setTimeout(cb, 0)
  }
}

export const formatDuration = duration => {
  if (duration < 60) {
    return Math.round(duration) + ' sec'
  }
  duration /= 60
  if (duration < 60) {
    return Math.round(duration) + ' min'
  }
  duration /= 24
  if (duration < 24) {
    if (Math.round(duration) === 1) return '1 hour'
    return Math.round(duration) + ' hours'
  }
}

export const getAudioDuration = src => new Promise(resolve => {
  var audio = new window.Audio()
  audio.addEventListener('loadedmetadata', function () {
    resolve(audio.duration)
  })
  audio.src = src
})

export const findUrlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

export const vimeoRegex = /<iframe[^>]+?src="(.+?)"[^>]*>/g

export const arrayForce = value => Array.isArray(value) ? Array.from(value) : []

const errItemToString = error => {
  const status = get(error, 'status')
  const code = get(error, 'code')
  const title = get(error, 'title')
  const detail = get(error, 'detail', get(error, 'message'))

  return [title, detail, code, status].filter(identity).join(', ')
}

export const errorToString = (error) => {
  const errList = get(error, 'errors', []).map(errItemToString)

  if (errList.length > 0) return errList.join('\n')

  const str = errItemToString(error)

  if (str.length > 0) return str

  else return '' + error
}

export const sleep = ms => new Promise(resolve => window.setTimeout(resolve, ms))

export const randomString = length => Math.floor(Math.random() * 36).toString(36) + (length > 1 ? randomString(length - 1) : '')

function hasRel (x) {
  return x && x.rel
}

function intoRels (acc, x) {
  function splitRel (rel) {
    acc[rel] = Object.assign({}, x, { rel: rel })
  }

  x.rel.split(/\s+/).forEach(splitRel)

  return acc
}

function createObjects (acc, p) {
  // rel="next" => 1: rel 2: next
  var m = p.match(/\s*(.+)\s*=\s*"?([^"]+)"?/)
  if (m) acc[m[1]] = m[2]
  return acc
}

function parseLink (link) {
  try {
    var m = link.match(/<?([^>]*)>(.*)/)

    var linkUrl = m[1]

    var parts = m[2].split(';')

    var qry = querystring.parse(linkUrl.split('?')[1])

    parts.shift()

    var info = parts
      .reduce(createObjects, {})

    info = Object.assign({}, qry, info)
    info.url = linkUrl
    return info
  } catch (e) {
    return null
  }
}

export const parseLinkHeader = linkHeader => {
  if (!linkHeader) return null

  return linkHeader.split(/,\s*</)
    .map(parseLink)
    .filter(hasRel)
    .reduce(intoRels, {})
}

export const googleCalenderDateFormat = a => {
  const date = new Date()
  const year = pad('0000', date.getFullYear(), true)
  const month = pad('00', date.getMonth() + 1, true)
  const day = pad('00', date.getDate(), true)
  const hour = pad('00', date.getHours(), true)
  const minutes = pad('00', date.getMinutes(), true)
  const seconds = pad('00', date.getSeconds(), true)
  return `${year}${month}${day}T${hour}${minutes}${seconds}`
}

export function pad (pad, str, padLeft) {
  if (typeof str === 'undefined') { return pad }
  if (padLeft) {
    return (pad + str).slice(-pad.length)
  } else {
    return (str + pad).substring(0, pad.length)
  }
}

export const formatDate = (date = undefined, delimiter = '-') => {
  const dateObj = date !== undefined && date !== null ? new Date(date) : new Date()
  var month = dateObj.getUTCMonth() + 1 // months from 1-12
  var day = dateObj.getUTCDate()
  var year = dateObj.getUTCFullYear()

  return year + delimiter + month + delimiter + day
}
