import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { List } from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'
import ClientsDrawerItems from '../../../containers/menus/ClientsDrawerItems'
import { COLLECTIONS } from '../../../constants/routes'
import upperFirst from 'lodash/upperFirst'
import ProjectsDrawerItems from '../../../containers/menus/ProjectsDrawerItems'
import * as TYPES from '../../../types';
import NavItem from '../NavItem/NavItem';
import HomeOutlined from '@material-ui/icons/HomeOutlined'
import ToolsDrawerItems from '../../../containers/menus/ToolsDrawerItems'
import GlobalDrawerItems from '../GlobalDrawerItems/GlobalDrawerItems';

const DefaultDrawerItems = props => {
  const { messages, user } = props

  return (
    <>
      <List disablePadding>
        <NavItem exact icon={<HomeOutlined />} text={get(messages, 'dashboard.message')} to={'/'} />
      </List>

      <GlobalDrawerItems user={user} messages={messages} />

      <List disablePadding>
        <ClientsDrawerItems />
      </List>

      <List disablePadding>
        <ProjectsDrawerItems messages={messages} user={user} />
      </List>

      {get(user, 'attributes.role') === 'admin' &&
        <List disablePadding>
          <NavItem icon={<BuildIcon />} text={upperFirst(get(messages, 'collectionEditor.message'))} to={COLLECTIONS} />
        </List>}

      {/* {get(user, 'attributes.role') === 'admin' &&
        <List disablePadding>
          <NavItem icon={<Calender />} text={upperFirst(get(messages, 'calender.message'))} to={CLIENT_CALENDER} />
        </List>} */}

      <List disablePadding>
        <ToolsDrawerItems messages={messages} user={user} />
      </List>
    </>
  )
}

GlobalDrawerItems.propTypes = {
  messages: TYPES.messages,
  user: TYPES.user
}

DefaultDrawerItems.defaultProps = {

}

export default DefaultDrawerItems;
