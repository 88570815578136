import React, { Component } from 'react'
import SettingsComponent from '../components/Settings/Settings'
import { compose } from 'redux'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { createSelector } from 'reselect'
import withInternationalization from '../hocs/Internationalization'
import Settings from '../lib/settings/Settings'

const stateSelector = createSelector(
  state => Settings.getAllSettings(),
  (set) => ({ ...set, isLoaded: true })
)

const enhance = compose(
  withInternationalization(),
  connect(
    stateSelector,
    dispatch => ({
      handleSave: set => {
        Settings.persist(set)
        window.location.reload()
      }
    })
  )
)

export default enhance(SettingsComponent)
