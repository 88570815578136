import React from 'react';
import { Switch } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import AppliedRoute from '../../../containers/AppliedRoute';
import DrawerItems from '../../../containers/menus/DrawerItems';
import ProjectsDrawerItems from '../ProjectDrawerItems/ProjectDrawerItems';

const DrawerRoutes = childProps => {
  return (
    <Switch>
      <AppliedRoute path={ROUTES.PROJECT} component={ProjectsDrawerItems} props={childProps} />
      <AppliedRoute component={DrawerItems} props={childProps} />
    </Switch>
  );
}

DrawerRoutes.propTypes = {

}

DrawerRoutes.defaultProps = {

}

export default DrawerRoutes;
