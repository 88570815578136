import { CircularProgress } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { compose } from 'redux'
import { readEndpoint } from 'redux-json-api'
import { createSelector } from 'reselect'
import ProjectsDrawerItemsComponent from '../../components/menus/ProjectsDrawerItems/ProjectsDrawerItems'
import withInternationalization from '../../hocs/Internationalization'
import { renderIfEmpty, renderWhile, renderWhileLoading } from '../../hocs/Loading'
import { errorToString } from '../../lib/util'

const fetchProjects = throttle(async (dispatch, userId) => {
  try {
    await dispatch(
      readEndpoint(`users/${userId}/relationships/projects`)
    )
  } catch (err) {
    toast.error(errorToString(err))
  }
}, 1000 * 8, { leading: true, trailing: false })

const withProjects = Component => props => {
  const uid = get(props, 'user.id')

  useEffect(() => {
    uid && props.fetchProjects(uid)
  }, [uid])

  return (
    <Component {...props} />
  )
}

const stateSelector = createSelector(
  state => get(state, 'auth.authData.user.data'),
  state => get(state, 'api.projects.data'),
  state => get(state, 'api.isReading', 0) > 0,
  (user, projects, isReading) => ({ user, projects, isLoading: !projects && isReading })
)

const enhance = compose(
  connect(
    stateSelector,
    (dispatch) => ({
      fetchProjects: (uid) => fetchProjects(dispatch, uid)
    })
  ),
  withProjects,
  withInternationalization(),
  renderWhile(props => props.isLoading, () => <CircularProgress />),
  renderWhileLoading(['user', 'messages', 'projects'], () => null),
  renderIfEmpty(['projects'], ({ messages }) => <Typography>{get(messages, 'noProjects.message')}</Typography>)
)

const enhancedComponent = enhance(ProjectsDrawerItemsComponent)

enhancedComponent.propTypes = {

}

enhancedComponent.defaultProps = {

}

export default enhancedComponent
