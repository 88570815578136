
import { recoverAuth as recoverAuthService, login as loginService, register as registerService, refresh as refreshAuthService } from '../../services/esserver/auth'
import { setAxiosConfig } from 'redux-json-api'
import Settings from '../../lib/settings/Settings'
import { JWT_TOKEN } from '../../constants/settings'
import { sleep } from '../../lib/util'

const initWithAuthData = dispatch => async data => {
  const user = data.user
  const jwtToken = data.jwtToken
  const store = await import('../../store')
  const config = store.default.getState().api.endpoint.axiosConfig
  await dispatch(setAxiosConfig(Object.assign({}, config, {
    timeout: 15000,
    headers: Object.assign({}, config.headers, {
      Authorization: `JWT ${jwtToken}`,
      'X-Apicache-Bypass': true
    })
  })))
  Settings.persist({ jwtToken })
  await sleep(1000) // sleep for 1000ms to give app time to propagate credentials
  return data
}

export const REFRESH_AUTH_PENDING = 'REFRESH_AUTH_PENDING'
export const REFRESH_AUTH_FULFILLED = 'REFRESH_AUTH_FULFILLED'
export const REFRESH_AUTH_REJECTED = 'REFRESH_AUTH_REJECTED'

export const refreshAuth = () => dispatch => {
  const oldJwtToken = Settings.getSetting('jwtToken')
  return dispatch({
    type: 'REFRESH_AUTH',
    payload: refreshAuthService(oldJwtToken)
      .then(initWithAuthData(dispatch))
  })
}

export const RECOVER_AUTH_PENDING = 'RECOVER_AUTH_PENDING'
export const RECOVER_AUTH_FULFILLED = 'RECOVER_AUTH_FULFILLED'
export const RECOVER_AUTH_REJECTED = 'RECOVER_AUTH_REJECTED'

export const recoverAuth = () => dispatch => dispatch({
  type: 'RECOVER_AUTH',
  payload: recoverAuthService()
    .then(initWithAuthData(dispatch))
})

export const LOGIN_AUTH_PENDING = 'LOGIN_AUTH_PENDING'
export const LOGIN_AUTH_FULFILLED = 'LOGIN_AUTH_FULFILLED'
export const LOGIN_AUTH_REJECTED = 'LOGIN_AUTH_REJECTED'

export const login = ({ email, password, recaptcha }) => dispatch => dispatch({
  type: 'LOGIN_AUTH',
  payload: loginService({ email, password, recaptcha })
    .then(initWithAuthData(dispatch))
})

export const REGISTER_AUTH_PENDING = 'REGISTER_AUTH_PENDING'
export const REGISTER_AUTH_FULFILLED = 'REGISTER_AUTH_FULFILLED'
export const REGISTER_AUTH_REJECTED = 'REGISTER_AUTH_REJECTED'

export const register = ({ email, recaptcha, username, password }) => dispatch => dispatch({
  type: 'REGISTER_AUTH',
  payload: registerService({ email, username, password, recaptcha })
    .then(initWithAuthData(dispatch))
})

export const LOGOUT = 'LOGOUT'

export const logout = () => {
  Settings.delete(JWT_TOKEN)
  return {
    type: LOGOUT
  }
}
