
import React, { Component } from 'react'
import AuthComponent from '../components/Auth/Auth'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { green } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { login, register } from '../actions/esserver/auth'
import FullScreenLoader from '../components/FullScreenLoader/FullScreenLoader'
import { renderWhileLoading } from '../hocs/Loading'
import { sleep, errorToString } from '../lib/util'
import { toast } from 'react-toastify'
import Settings from '../lib/settings/Settings'

const stateSelector = createSelector(
  state => state.auth,
  settings => Settings.getAllSettings(),
  (auth, settings) => ({ auth, settings })
)

const matStyles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    position: 'relative',
    minHeight: 200
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500]
  }
})

const enhance = compose(
  withRouter,
  withStyles(matStyles, { withTheme: true }),
  connect(
    stateSelector,
    dispatch => ({
      login: async ({ email, password, recaptcha }) => {
        try {
          await dispatch(login({ email, password, recaptcha }))
          await sleep(100)
          window.location.reload()
        } catch (err) {
          toast.error(errorToString(err))
          console.error(err)
        }
      },
      register: async ({ email, username, password, recaptcha }) => {
        try {
          await dispatch(register({ email, username, password, recaptcha }))
          await sleep(100)
          window.location.reload()
        } catch (err) {
          toast.error(errorToString(err))
          console.error(err)
        }
      }
    })
  ),
  Component => props => <Component {...props} />
)

export default enhance(AuthComponent)
