import React, { useState, useEffect, useRef } from 'react';
import styles from './LoadingContainer.module.css';
import propTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { CircularProgress } from '@material-ui/core'

const LoadingContainer = ({ isLoading, children, defaultHeight }) => {
  const [height, setHeight] = useState()
  const ref = useRef()

  useEffect(() => {
    setHeight(ref.current.clientHeight)
  }, [isLoading])

  const style = isLoading ? { height: (height || defaultHeight) + 'px' } : undefined

  return (
    <div style={style} ref={ref} className={clsx(styles.LoadingContainer, isLoading && styles.isLoading)}>
      {children}
      <div className={clsx(styles.overlay, isLoading && styles.isLoading)}>
        <CircularProgress className={styles.loadingSpinner} />
      </div>
    </div>
  );
}

LoadingContainer.propTypes = {
  isLoading: propTypes.bool.isRequired,
  children: propTypes.node.isRequired
}

LoadingContainer.defaultProps = {
  isLoading: false,
  defaultHeight: 350
}

export default LoadingContainer;
