
import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { compose } from 'redux';
import * as ROUTES from '../constants/routes';
import withComponent from '../hocs/AsyncComponent';
import AppliedRoute from './AppliedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
// import { HashRouter as Router } from 'react-router-dom'
import Router from './DebugRouter';

// const NotFoundPage = withComponent(() =>
// import('../containers/NotFoundPage'))
import NotFoundPage from '../containers/NotFoundPage';
import Dashboard from '../containers/Dashboard';
import Settings from '../containers/Settings';
import Login from '../components/Login/Login';
import Register from '../components/Register/Register';
import ClientsRoot from '../components/ClientsRoot/ClientsRoot';
import Client from '../containers/Client';

const WebPushFeedNew = withComponent(() => import('../components/WebPushFeedNew/WebPushFeedNew'))
const Documents = withComponent(() => import('../containers/Documents'))
const DocumentType = withComponent(() => import('./DocumentType'))
const DocumentTypeSpecific = withComponent(() => import('./DocumentTypeSpecific'))
const ClientUsers = withComponent(() => import('./ClientUsersPage'))
const Calender = withComponent(() => import('./Calender'))
const CollectionEditorPage = withComponent(() => import('./CollectionEditor'))
const CollectionEditorCollectionItem = withComponent(() => import('./CollectionEditorCollectionItem'))
const Projects = withComponent(() => import('./Projects'))
const Project = withComponent(() => import('./Project'))
const CollectionItem = withComponent(() => import('./CollectionItemPage'))
const Piece = withComponent(() => import('./Piece'))
const UserProfile = withComponent(() => import('./UserProfile'))
const AuthorizeGithub = withComponent(() => import('./AuthorizeGithub'))
const NewEmbeds = withComponent(() => import('./NewEmbeds'))
const ProjectEmbed = withComponent(() => import('./ProjectEmbed'))
const ProjectMediaGalleryPage = withComponent(() => import('./ProjectMediaGalleryPage'))
const SoundtracksOverview = withComponent(() => import('./soundtracks/SoundtracksOverview'))
const ResponsiveImagesOverviewPage = withComponent(() => import('./ResponsiveImagesOverviewPage'))
const SpecificImagePage = withComponent(() => import('./SpecificImagePage'))
const SoundtrackSpecificPage = withComponent(() => import('./soundtracks/SoundtrackSpecificPage'))
const TriggersPage = withComponent(() => import('./triggers/TriggersPage'))
const TriggerPage = withComponent(() => import('./triggers/TriggerPage'))
const AddGithubCommitsChangelogPage = withComponent(() => import('./AddGithubCommitsChangelogPage'))
const SpecificProjectGithubCommitsChangelogPage = withComponent(() => import('./SpecificProjectGithubCommitsChangelogPage'))
const SpecificFilePage = withComponent(() => import('./SpecificFilePage'))
const GoogleMapsHTMLSnippet = withComponent(() => import('../components/snippets/GoogleMapsHTMLSnippet/GoogleMapsHTMLSnippet'))
const ProjectPaymentMethods = withComponent(() => import('./ProjectPaymentMethods'))
const ProjectPaymentMethod = withComponent(() => import('./ProjectPaymentMethod'))
const ProjectIntegrations = withComponent(() => import('./ProjectIntegrations'))
const ProjectIntegrationsGithub = withComponent(() => import('./ProjectIntegrationsGithub'))

const Routes = props => {
  const childProps = {
    ...props.auth
  }

  return (
    <Router>
      <Switch>
        <AppliedRoute path={ROUTES.SIGN_IN} component={Login} props={childProps} />
        <AppliedRoute path={ROUTES.REGISTER} component={Register} props={childProps} />
        <AuthenticatedRoute exact path={ROUTES.DASHBOARD} component={Dashboard} props={childProps} />
        <AppliedRoute path={ROUTES.SETTINGS} component={Settings} props={childProps} />

        <AuthenticatedRoute path={ROUTES.ADMINISTRATION_DOCUMENTS_TYPE_SPECIFIC} component={DocumentTypeSpecific} props={childProps} />
        <AuthenticatedRoute path={ROUTES.ADMINISTRATION_DOCUMENTS_TYPE} component={DocumentType} props={childProps} />
        <AuthenticatedRoute path={ROUTES.ADMINISTRATION_DOCUMENTS} component={Documents} props={childProps} />

        <AuthenticatedRoute props={childProps} path={ROUTES.USER} component={UserProfile} />

        <AuthenticatedRoute props={childProps} path={ROUTES.CLIENT_CALENDER} component={Calender} />
        <AuthenticatedRoute props={childProps} path={ROUTES.WEBPUSHFEED_NEW} component={({ match }) => <WebPushFeedNew {...match.params} />} />
        <AuthenticatedRoute props={childProps} path={ROUTES.WEBPUSHFEED_EDIT} component={({ match }) => <WebPushFeedNew {...match.params} />} />
        <AuthenticatedRoute props={childProps} path={ROUTES.CLIENT_USERS} component={ClientUsers} />
        <AuthenticatedRoute props={childProps} path={ROUTES.SOUNDTRACK_SPECIFIC} component={SoundtrackSpecificPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.IMAGE_SPECIFIC} component={SpecificImagePage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_GITHUB_COMMITS_CHANGELOG} component={SpecificProjectGithubCommitsChangelogPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_NEW_GITHUB_COMMITS_CHANGELOG} component={AddGithubCommitsChangelogPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_IMAGES_OVERVIEW_PAGED} component={ResponsiveImagesOverviewPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_IMAGES_OVERVIEW} component={ResponsiveImagesOverviewPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_SOUNDTRACK_OVERVIEW} component={SoundtracksOverview} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_SPECIFIC_FILE} component={SpecificFilePage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_MEDIA_GALLERY_PAGE} component={ProjectMediaGalleryPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_MEDIA_GALLERY} component={ProjectMediaGalleryPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_SPECIFIC_EMBED_SPECIFIC} component={ProjectEmbed} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_SPECIFIC_NEW_EMBED} component={NewEmbeds} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_PAYMENT_METHODS_SPECIFIC} component={ProjectPaymentMethod} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_PAYMENT_METHODS} component={ProjectPaymentMethods} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_TRIGGER_SPECIFIC_PAGE} component={TriggerPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_TRIGGERS_PAGE} component={TriggersPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_INTEGRATIONS_GITHUB} component={ProjectIntegrationsGithub} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_INTEGRATIONS} component={ProjectIntegrations} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT} component={Project} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECTS} component={Projects} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PIECE_SPECIFIC} component={Piece} />
        <AuthenticatedRoute props={childProps} path={ROUTES.COLLECTION_EDITOR_SPECIFIC} component={CollectionEditorCollectionItem} />
        <AuthenticatedRoute props={childProps} path={ROUTES.COLLECTION_SPECIFIC_PAGINATED} component={CollectionItem} />
        <AuthenticatedRoute props={childProps} path={ROUTES.COLLECTION_SPECIFIC} component={CollectionItem} />
        <AuthenticatedRoute props={childProps} path={ROUTES.COLLECTIONS} component={CollectionEditorPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.CLIENT} component={({ match }) => <Client {...match.params} />} />
        <AuthenticatedRoute props={childProps} path={ROUTES.CLIENTS} component={ClientsRoot} />
        <AuthenticatedRoute props={childProps} path={ROUTES.AUTHORIZE_GITHUB} component={AuthorizeGithub} />
        
        <AppliedRoute props={childProps} path={ROUTES.TOOLS_SNIPPETS_GOOGLE_MAPS_HTML} component={GoogleMapsHTMLSnippet} />
        <AppliedRoute props={childProps} path="/" component={NotFoundPage} />
      </Switch>
    </Router >
  )
}

export default compose(
  connect(
    ({ auth }) => ({
      auth
    }),
    null
  )
)(Routes)
