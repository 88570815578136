import React, { Component } from 'react'
import NewClientComponent from '../components/NewClient/NewClient'
import { compose } from 'redux'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { createSelector } from 'reselect'
import { createResource } from 'redux-json-api'

const stateSelector = createSelector(
  state => state.property1,
  (property1) => ({ property1 })
)

const enhance = compose(
  connect(
    stateSelector,
    dispatch => ({
      postClient: ({ data }) => dispatch(createResource({
        type: 'documents',
        attributes: data
      }))
    })
  )
)

export default enhance(NewClientComponent)
