import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@material-ui/core'
import { TableBody } from '@material-ui/core'
import { TableCell } from '@material-ui/core'
import { TableHead } from '@material-ui/core'
import { TableRow } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import Page from '../Page/Page'
import { Typography } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { LinearProgress } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import WebPushFeeds from '../WebPushFeeds/WebPushFeeds'
import Notifications from '../../containers/Notifications'
import FullScreenLoader from '../FullScreenLoader/FullScreenLoader'
import ClientDetails from '../../containers/ClientDetail'
import get from 'lodash/get'
import ClientUsers from '../../containers/ClientUsers'

class Client extends Component {
  constructor (props) {
    super()
  }

  render () {
    const { classes, client, messages } = this.props

    const isLoading = !client

    console.log('client', client)

    return (
      <Page title={get(client, 'name', 'Client')}>
        <LinearProgress className={isLoading ? classes.visible : classes.hidden} />
        {
          !isLoading &&
          (
            <>
              <Typography variant="h3">Details</Typography>
              <br />
              <ClientDetails key={client.id} client={client} />
              <br />
              <Typography variant="h3">Users</Typography>
              <br/>
              <ClientUsers clientId={client.id} />
              <br/>
              <Typography variant="h3">Notifications</Typography>
              <br />
              <Notifications clientId={client.id} />
              <br />
              <Typography variant="h3">{get(messages, 'revenue.message')}</Typography>
            </>
          )
        }
      </Page>
    )
  }
}

Client.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object,
  messages: PropTypes.object
}

export default Client
