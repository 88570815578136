import { CircularProgress } from '@material-ui/core'
import get from 'lodash/get'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import ToolsDraweritems from '../../components/menus/ToolsDraweritems/ToolsDraweritems'
import withInternationalization from '../../hocs/Internationalization'
import { renderWhile } from '../../hocs/Loading'

const stateSelector = createSelector(
  state => get(state, 'auth.authData.user.data'),
  (user) => ({ user })
)

const enhance = compose(
  connect(
    stateSelector,
    (dispatch) => ({
    })
  ),
  withInternationalization(),
  renderWhile(props => props.isLoading, () => <CircularProgress />)
)

const enhancedComponent = enhance(ToolsDraweritems)

enhancedComponent.propTypes = {

}

enhancedComponent.defaultProps = {

}

export default enhancedComponent
