import React, { Component, useEffect } from 'react'
import ClientsDrawerItemsComponent from '../../components/menus/ClientsDrawerItems/ClientsDrawerItems'
import { compose } from 'redux'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import { readEndpoint } from 'redux-json-api'
import withInternationalization from '../../hocs/Internationalization'

const withClients = Component => props => {
  useEffect(() => {
    const role = get(props.user, 'attributes.role')
    const { apiMetaStatus } = props
    if (role === 'admin' && !('clients' in apiMetaStatus)) props.fetchClients()
  }, [])

  return <Component {...props} />
}

const stateSelector = createSelector(
  state => get(state, 'api.clients.data', []),
  state => get(state, 'auth.authData.user.data'),
  state => get(state, 'apiMeta.status'),
  (clients, user, apiMetaStatus) => ({ clients, user, apiMetaStatus })
)

const enhance = compose(
  connect(
    stateSelector,
    dispatch => ({
      fetchClients: () => {
        dispatch(readEndpoint('clients'))
      }
    })
  ),
  withClients,
  withInternationalization()
)

export default enhance(ClientsDrawerItemsComponent)
