import { settingGet } from '../../lib/redux/util'
import { hydrateStore } from 'redux-json-api';

const AUTHORIZE_BASE_URL = 'oauth/github/authorize'
const TOKEN_BASE_URL = 'oauth/github/token'

export const GITHUB_GET_OAUTH_TOKEN = 'GITHUB_GET_OAUTH_TOKEN'
export const GITHUB_GET_OAUTH_TOKEN_FULFILLED = 'GITHUB_GET_OAUTH_TOKEN_FULFILLED'
export const GITHUB_GET_OAUTH_TOKEN_REJECTED = 'GITHUB_GET_OAUTH_TOKEN_REJECTED'
export const GITHUB_GET_OAUTH_TOKEN_PENDING = 'GITHUB_GET_OAUTH_TOKEN_PENDING'

const PUBLIC_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.PUBLIC_URL

export const authorize = () => dispatch => {
  return dispatch({
    type: GITHUB_GET_OAUTH_TOKEN,
    payload: getAuthToken()
  })
}

const getCodeFromPopup = cmsHost => new Promise((resolve, reject) => {
  console.log(process.env)
  const url = new URL(AUTHORIZE_BASE_URL, cmsHost).href + '?redirect_uri=' + encodeURIComponent(PUBLIC_URL + '/oauth-callbacks/github-app-popup.html')
  let done = false
  try {
    const listener = event => {
      if (done) return
      const { data } = event
      if (data.type !== 'oauth-callbacks-github-popup') return
      done = true
      window.clearInterval(pollTimer)
      window.removeEventListener('message', listener)

      if (data.payload.error) reject(new Error(data.payload.error))
      else resolve(data.payload)
    }

    window.addEventListener('message', listener)

    console.log('url', url)

    const popup = window.open(url, '_blank', 'menubar=0,resizable=0,width=350,height=600')

    const handlePopupFailed = () => {
      if (done) return
      done = true
      window.removeEventListener('message', listener)

      const error = {
        title: 'Popup window was closed.',
        detail: 'Popup window was closed before we could verify with github.',
        code: 'github-authorize-popup-closed'
      }
      reject(error)
    }

    var pollTimer = window.setInterval(() => {
      if (popup.closed !== false) { // !== is required for compatibility with Opera
        window.clearInterval(pollTimer)
        handlePopupFailed()
      }
    }, 200)
  } catch (err) {
    reject(err)
  }
})

const getAuthToken = async () => {
  const cmsHost = await settingGet('cmsHost')
  const jwtToken = await settingGet('jwtToken')
  const { code, state } = await getCodeFromPopup(cmsHost)
  const url = new URL(TOKEN_BASE_URL, cmsHost).href

  const response = await window.fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `JWT ${jwtToken}`,
      'Content-Type': 'application/json',
      'X-Apicache-Bypass': true
    },
    body: JSON.stringify({ code, state })
  })
  const data = response.json()
  if (!response.ok) throw data
  const token = data
  return token
}
