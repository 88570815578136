import { useSelector } from 'react-redux'
import get from 'lodash/get'

const useIntl = () => {
  const messages = useSelector(state => get(state, 'internationalization.messages'))
  const intl = (key, ...values) => get(messages, [key, 'message'])
  intl.messages = messages
  return intl
};

export default useIntl
