
class DefaultSettings {
    cmsHost = 'https://api.esstudio.nl';
    clientId = '4';
    recaptchaSiteKey = '6Ld6RlAUAAAAANSKdPZXXFXaFZRuVECZo303qfR8';
    jwtToken = null;
    title = ''
    password = '';
    email = '';
}

export default new DefaultSettings()
