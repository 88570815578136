import React, { Component } from 'react'
import styles from './UsersGrid.module.css'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Table } from '@material-ui/core'
import { TableBody } from '@material-ui/core'
import { TableCell } from '@material-ui/core'
import { TableHead } from '@material-ui/core'
import { TableRow } from '@material-ui/core'
import get from 'lodash/get'
import { compose } from 'redux'

const mdStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  }
})

const columns = [
  {
    name: 'Username',
    key: 'attributes.username',
    map: x => x
  },
  {
    name: 'Description',
    key: 'attributes.description',
    map: x => x
  },
  {
    name: 'Email',
    key: 'attributes.email',
    map: x => x
  },
  {
    name: 'Role',
    key: 'attributes.role',
    map: x => x
  },
  {
    name: 'Full name',
    key: 'attributes',
    map: x => [x.fname, x.mname, x.lname].join(' ')
  }
]

class UsersGrid extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentWillMount () {
    this.props.getUsers()
  }

  componentWillUnmount () {
  }

  render () {
    const { classes, users, history: { push } } = this.props

    return (
      <div className={classes.root}>

        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {
                columns.map(({ key, name, map }, i) => {
                  return (
                    <TableCell key={key} align={i > 0 ? 'right' : 'left'}>
                      <b>
                        {name}
                      </b>
                    </TableCell>
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => {
              const route = ''
              // const route = ROUTES.ADMINISTRATION_UsersGrid_TYPE_SPECIFIC
              //   .replace(':docType', document.attributes.docType)
              //   .replace(':id', document.id)
              return (
                <TableRow key={user.id} onClick={() => push(route)}>
                  {
                    columns.map(({ key, name, map }, i) => {
                      return (
                        <TableCell key={key} align={i > 0 ? 'right' : 'left'}>
                          <div className={classes.minHeight}>
                            {map(get(user, key, 'N/A'))}
                          </div>
                        </TableCell>
                      )
                    })
                  }

                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

UsersGrid.propTypes = {
  getUsers: propTypes.func.isRequired,
  users: propTypes.array.isRequired,
  clientId: propTypes.string.isRequired
}

UsersGrid.defaultProps = {

}

const enhance = compose(
  withStyles(mdStyles, { withTheme: true }),
  withRouter
)

const enhanced = enhance(UsersGrid)

export default enhanced
