import { Avatar, Divider, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import laikaBlue from '../../../images/laika-cms.png';
import RawLink from '../../RawLink/RawLink';
import CustomDrawerItems from '../CustomDrawerItems/CustomDrawerItems';
import DefaultDrawerItems from '../DefaultDrawerItems/DefaultDrawerItems';

const DrawerItems = props => {
  const { classes, menuPiece, messages, user } = props

  const items = get(menuPiece, 'attributes.structure.items')

  return (
    <div>
      <RawLink className={classes.toolbar} to="/">
        <ListItem button>
          <ListItemAvatar>
            <Avatar 
              alt="Logo"
              src={laikaBlue}
            />
          </ListItemAvatar>
          <ListItemText primaryTypographyProps={{ variant: 'h4', color: 'primary' }} primary={get(messages, 'cmsTitle.message')} />
        </ListItem>
      </RawLink>
      <Divider />
      {items
        ? <CustomDrawerItems messages={messages} user={user} menuItems={items} />
        : <DefaultDrawerItems messages={messages} user={user} />}
    </div>
  )
}

DrawerItems.propTypes = {
  classes: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  user: PropTypes.object
}

export default DrawerItems
