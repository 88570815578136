import propTypes from 'prop-types';
import React, { Component } from 'react';
import { errorToString, logError } from '../../lib/util';

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false, message: 'Unknown error' }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    logError(error)
    return { hasError: true, message: errorToString(error) }
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    logError(error, errorInfo)
    this.setState({ hasError: true, message: errorToString(error) })
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>{this.props.title}</h1>
          <p>{this.state.message || this.props.message}</p>
        </>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  message: propTypes.string,
  title: propTypes.title
}

ErrorBoundary.defaultProps = {
  title: 'Something went wrong...'
}

export default ErrorBoundary
