import React, { Component } from 'react'
import styles from './Alert.module.css'
import propTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { amber, green } from '@material-ui/core/colors'
import { IconButton } from '@material-ui/core'
import { Snackbar } from '@material-ui/core'
import { SnackbarContent } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { Button } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const mdStyles = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    flexWrap: 'nowrap'
  }
})

class Alert extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentWillMount () {
  }

  componentWillUnmount () {
  }

  render () {
    const { classes, className, message, variant, ...other } = this.props
    const Icon = variantIcon[variant]

    return (
      <SnackbarContent
        className={clsx(classes[variant], classes.root, className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        {...other}
      />
    )
  }
}

Alert.propTypes = {
  className: propTypes.string,
  message: propTypes.string,
  onClose: propTypes.func,
  variant: propTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
}

Alert.defaultProps = {

}

export default withStyles(mdStyles)(Alert)
