
import { shape, string } from 'prop-types'

export const relationshipLinksShape = shape({
  self: string
})

export const relationshipDataShape = shape({
  id: string,
  type: string
})

export const relationshipShape = shape({
  data: relationshipDataShape,
  links: relationshipLinksShape
})

