
export const SIGN_IN = '/signin'
export const REGISTER = '/register'
export const DASHBOARD = '/'
export const SETTINGS = '/settings'
export const USER = '/user/:uid'
export const WEBPUSHFEED_NEW = '/clients/:clientId/notifications/webpushfeeds/new'
export const WEBPUSHFEED_EDIT = '/clients/:clientId/notifications/webpushfeeds/:feedid/edit'
export const CLIENT = '/clients/:clientId'
export const CLIENT_USERS = '/clients/:clientId/users'
export const CLIENTS = '/clients'
export const CLIENT_CALENDER = '/clients/calender'
export const PROJECTS = '/projects'
export const PROJECT = '/projects/:project'
export const COLLECTIONS = '/collection'
export const COLLECTION_EDITOR_SPECIFIC = '/collections/:collection/schema'
export const COLLECTION_SPECIFIC = '/collections/:collection/'
export const COLLECTION_SPECIFIC_PAGINATED = '/collections/:collection/page/:page'
export const PIECE_SPECIFIC = '/pieces/:piece'
export const AUTHORIZE_GITHUB = '/authorize/github'
export const ADMINISTRATION_DOCUMENTS = '/projects/:project/administration'
export const ADMINISTRATION_DOCUMENTS_TYPE = '/projects/:project/administration/documents/:docType'
export const ADMINISTRATION_DOCUMENTS_TYPE_SPECIFIC = '/projects/:project/administration/documents/:docType/:id'
export const PROJECT_SPECIFIC_NEW_EMBED = '/projects/:project/new-embed'
export const PROJECT_SPECIFIC_EMBED_SPECIFIC = '/projects/:project/embeds/:embed'
export const PROJECT_MEDIA_GALLERY = '/projects/:project/media'
export const PROJECT_MEDIA_GALLERY_PAGE = '/projects/:project/media/:page'
export const PROJECT_SOUNDTRACK_OVERVIEW = '/projects/:project/soundtracks'
export const PROJECT_IMAGES_OVERVIEW_PAGED = '/projects/:project/images/:page'
export const PROJECT_IMAGES_OVERVIEW = '/projects/:project/images'
export const PROJECT_NEW_GITHUB_COMMITS_CHANGELOG = '/projects/:project/new-github-commits-changelog'
export const PROJECT_GITHUB_COMMITS_CHANGELOG = '/projects/:project/github-commits-changelogs/:githubCommitsChangelog'
export const PROJECT_PAYMENT_METHODS = '/projects/:project/payment-methods'
export const PROJECT_PAYMENT_METHODS_SPECIFIC = '/projects/:project/payment-methods/:paymentMethod'
export const IMAGE_SPECIFIC = '/images/:image'
export const SOUNDTRACK_SPECIFIC = '/projects/:project/soundtracks/:soundtrack'
export const PROJECT_INTEGRATIONS = '/projects/:project/integrations'
export const PROJECT_INTEGRATIONS_GITHUB = '/projects/:project/integrations/github'
export const CREATE_GITHUB_COMMITS_CHANGELOG = '/projects/:project/create-github-commits-changelog'
export const PROJECT_SPECIFIC_FILE = '/projects/:project/files/:file'
export const TOOLS = '/tools'
export const TOOLS_SNIPPETS = '/tools/snippets'
export const TOOLS_SNIPPETS_GOOGLE_MAPS_HTML = '/tools/snippets/google-maps-html'
export const PROJECT_TRIGGERS_PAGE = '/projects/:project/triggers'
export const PROJECT_TRIGGER_SPECIFIC_PAGE = '/projects/:project/triggers/:trigger'
