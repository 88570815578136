import { useSelector } from 'react-redux'
import get from 'lodash/get'

const useJsonApiError = (endpoint) => {
  const error = useSelector(state => {
    return get(state, ['apiMeta', 'status', endpoint, 'type']) === 'API_READ_FAILED' && 
      get(state, ['apiMeta', 'status', endpoint, 'payload'])
  })
  return error
};

export default useJsonApiError
