import React, { Component } from 'react'
import './ClientsRoot.css'
import Page from '../Page/Page'
import NewClient from '../../containers/NewClient'
import { Typography } from '@material-ui/core'

class ClientsRoot extends Component {
  constructor (props) {
    super()
    this.state = {
    }
  }

  componentWillUnmount () {
  }

  render () {
    return (
      <div className="ClientsRoot">
        <Page title="Clients">
          <Typography variant="h3" />
          <br />
          <NewClient />
        </Page>
      </div>
    )
  }
}

export default ClientsRoot
