import { Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AdministrationIcon from '@material-ui/icons/ChromeReaderMode';
import get from 'lodash/get';
import propTypes from 'prop-types';
import React, { useState } from 'react';
import documentTypes from '../../../constants/document-types';
import * as ROUTES from '../../../constants/routes';
import NavItem from '../NavItem/NavItem';

const useStyles = makeStyles(theme => ({
  leftPad: {
    paddingLeft: theme.spacing(2)
  }
}))

let _show = false

const AdministrationDrawerItems = props => {
  const [show, setShow] = useState(_show)
  _show = show

  const handleCollapse = () => {
    setShow(!show)
  }

  const { projectId, messages } = props
  const classes = useStyles()

  return (
    <div className="AdministrationDrawerItems">
      <NavItem onClick={handleCollapse} text={get(messages, 'administration.message')} to={ROUTES.ADMINISTRATION_DOCUMENTS.replace(':project', projectId)} disableLink icon={<AdministrationIcon />} />
      <Collapse key="clients items" in={show} timeout="auto" unmountOnExit>
        <div className={classes.leftPad}>
          <NavItem exact to={ROUTES.ADMINISTRATION_DOCUMENTS.replace(':project', projectId)} text={get(messages, 'documentVault.message')} icon={<AdministrationIcon />} />
          {documentTypes.map(({ key, name, image }) => {
            const to = ROUTES.ADMINISTRATION_DOCUMENTS_TYPE.replace(':docType', key).replace(':project', projectId)
            return <NavItem to={to} key={key} text={`${get(messages, 'new.message')} ${name}`} icon={<AdministrationIcon />} />
          })}
        </div>
      </Collapse>
    </div>
  )
}

AdministrationDrawerItems.propTypes = {
  projectId: propTypes.string.isRequired,
  messages: propTypes.object.isRequired
}

AdministrationDrawerItems.defaultProps = {

}

export default AdministrationDrawerItems
