import React, { Component } from 'react'
import styles from './Errors.module.css'
import propTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import Alert from '../Alert/Alert'
import { makeStyles } from '@material-ui/core/styles'

const mdStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}))

class Errors extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentWillMount () {
  }

  componentWillUnmount () {
  }

  render () {
    const { errors, children } = this.props
    const array = errors || children
    return Array.isArray(array) && (errors || children).map((error, i) => {
      const code = get(error, 'code', 'No error code')
      const detail = get(error, 'detail', 'No error mesesage')
      const title = get(error, 'title', 'No error title')
      const status = get(error, 'status', 'No error status')

      return (
        <div key={JSON.stringify(error)} className={styles.Errors}>
          <Alert
            title={`[code="${code}"] [status="${status}"]`}
            variant="error"
            className={mdStyles.margin}
            message={`${title}: ${detail}`}
          />
        </div>
      )
    })
  }
}

Errors.propTypes = {
  errors: propTypes.arrayOf(propTypes.shape({
    code: propTypes.string.isRequired,
    status: propTypes.string,
    detail: propTypes.string,
    title: propTypes.string
  }))
}

Errors.defaultProps = {
  errors: []
}

export default Errors
