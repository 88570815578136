import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider } from '@material-ui/core'
import { List } from '@material-ui/core'
import { ListItem } from '@material-ui/core'
import { ListItemIcon } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
import { Collapse } from '@material-ui/core'
import RawLink from '../../RawLink/RawLink'
import styles from './ClientsDrawerItems.module.css'
import FullScreenLoader from '../../FullScreenLoader/FullScreenLoader'
import { CircularProgress } from '@material-ui/core'
import ExtensionIcon from '@material-ui/icons/Extension'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import PeopleIcon from '@material-ui/icons/People'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'

let expanded = false

const matStyles = theme => ({
  padded: {
    padding: theme.spacing(2)
  }
})

class ClientsDrawerItemsComponent extends Component {
  constructor (props) {
    super()
    this.state = {
      show: expanded
    }
  }

  handleCollapse = () => {
    expanded = !this.state.show
    this.setState({ show: !this.state.show })
  }

  render () {
    const { classes, clients, messages } = this.props

    if (!Array.isArray(clients)) return null

    return (
      <div className="ClientsDrawerItems">
        <ListItem button onClick={this.handleCollapse}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={get(messages, 'clients.message')} />
        </ListItem>
        <Collapse key="clients items" component="li" in={this.state.show} timeout="auto" unmountOnExit>
          <RawLink key="overview" to={'/clients'}>
            <ListItem button>
              <ListItemIcon>
                <PeopleOutlineIcon />
              </ListItemIcon>
              <ListItemText className={classes.padLeft} primary={get(messages, 'listofClients.message')} />
            </ListItem>
          </RawLink>
          {clients.map(client => (
            <RawLink key={client.id} to={'/clients/' + client.id}>
              <ListItem button>
                <ListItemIcon>
                  <PermIdentityIcon />
                </ListItemIcon>
                <ListItemText style={{ paddingLeft: 20 }} primary={client.attributes.name} />
              </ListItem>
            </RawLink>
          ))}
        </Collapse>
      </div>
    )
  }
}

ClientsDrawerItemsComponent.propTypes = {
  clients: PropTypes.array.isRequired,
  messages: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

const enhance = compose(
  withStyles(matStyles, { withTheme: true })
)

const ClientsDrawerItems = enhance(ClientsDrawerItemsComponent)

export default ClientsDrawerItems
