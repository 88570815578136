import { FormControl } from '@material-ui/core';
import { FormGroup } from '@material-ui/core';
import { FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import get from 'lodash/get';
import { DropzoneArea } from 'material-ui-dropzone';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import styles from './ClientDetails.module.css';

const matStyle = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
})

class ClientDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      values: get(props, 'client.attributes', {})
    }
  }

  componentWillMount () {
  }

  componentWillUnmount () {
  }

  handleChange = key => e => {
    this.setState(prevstate => {
      prevstate.values[key] = e.target.value
    })
  }

  handleFileChange = key => files => {
    this.setState(prevstate => {
      prevstate.values[key] = files[0]
    })
  }

  onSubmit = e => {
    e.preventDefault()
    const { clientId, client: { id }, updateClientsData } = this.props
    const { values } = this.state

    const formData = new window.FormData()
    Object.keys(values).forEach(key => {
      if (values[key]) {
        formData.append(key, values[key])
      }
    })

    updateClientsData({ formData, clientId })
  }

  render () {
    const { classes, schema, onSubmit, onCancel, onChange, formData } = this.props
    const { values } = this.state

    return (
      <div className={styles.ClientDetails}>
        <form onSubmit={this.onSubmit} ref={ref => { this.formRef = ref }}>
          <FormGroup>
            <FormControl>
              <TextField
                label="Name"
                name="name"
                className={classes.textField}
                value={values.name || ''}
                onChange={this.handleChange('name')}
                margin="normal"
                variant="outlined"
              />
            </FormControl>

            <FormControl>
              <TextField
                label="Url"
                type="url"
                className={classes.textField}
                name="url"
                value={values.url || ''}
                onChange={this.handleChange('url')}
                margin="normal"
                variant="outlined"
              />
            </FormControl>
            <FormControl>
              <br />
              <FormLabel>Logo</FormLabel>

              {values.logo && <img width="300" alt="Logo" src={values.logo.secure_url} />}
              <br />

              <DropzoneArea
                filesLimit={1}
                acceptedFiles={['image/png', 'image/jpeg', 'image/svg+xml']}
                onChange={this.handleFileChange('logo')}
                dropzoneText={'Logo'}
              />
              <br />
            </FormControl>
            <FormControl>
              <FormLabel>Signature</FormLabel>

              <br />

              {values.signature && <img width="300" alt="Signature" src={values.signature.secure_url} />}

              <DropzoneArea
                filesLimit={1}
                acceptedFiles={['image/png', 'image/jpeg', 'image/svg+xml']}
                onChange={this.handleFileChange('signature')}
                dropzoneText={'Signature'}
              />
            </FormControl>
          </FormGroup>

          <br />
          <Button type="submit">Submit</Button>
        </form>

      </div>
    )
  }
}

ClientDetails.propTypes = {
  clientId: propTypes.string,
  client: propTypes.object
}

ClientDetails.defaultProps = {

}

export default withStyles(matStyle, { withTheme: true })(ClientDetails)
