import React, { Component, useState } from 'react'
import styles from './ProjectsDrawerItems.module.css'
import propTypes from 'prop-types'
import { Collapse } from '@material-ui/core'
import * as ROUTES from '../../../constants/routes'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import SupervisorAccountIconOutline from '@material-ui/icons/SupervisorAccountOutlined'
import get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import NavItem from '../NavItem/NavItem'

const useStyles = makeStyles(theme => ({
  leftPad: {
    paddingLeft: theme.spacing(2)
  }
}))

let expanded = false

const ProjectsDrawerItems = props => {
  const [show, setShow] = useState(expanded)
  expanded = show

  const { projects, user, messages } = props

  const toggleProjects = () => setShow(!show)

  const classes = useStyles()

  if (!Array.isArray(projects)) return null

  return (
    <div className="ProjectsDrawerItems">
      <NavItem onClick={toggleProjects} disableLink to="/projects" text={get(messages, 'projects.message', 'Projects')} icon={<AccountTreeIcon />} />
      <div className={classes.leftPad}>
        <Collapse key="project items" component="li" in={show} timeout="auto" unmountOnExit>
          <NavItem
            exact
            icon={<SupervisorAccountIcon />}
            text={get(messages, 'listOfProjects.message', 'List of projects')}
            to={'/projects'} />

          {projects.map(project => (
            <div key={project.id}>
              <NavItem
                icon={<SupervisorAccountIconOutline />}
                text={project.attributes.name} 
                to={ROUTES.PROJECT.replace(':project', project.id)} />
            </div>
          ))}
        </Collapse>
      </div>
    </div>
  )
}

ProjectsDrawerItems.propTypes = {
  projects: propTypes.array.isRequired
}

export default ProjectsDrawerItems
