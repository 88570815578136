import { Collapse, ListItem, Avatar, ListItemText, Divider, ListItemAvatar, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SupervisorAccountIconOutline from '@material-ui/icons/SupervisorAccountOutlined';
import get from 'lodash/get';
import propTypes from 'prop-types';
import React, { useState } from 'react';
import * as ROUTES from '../../../constants/routes';
import AdministrationDrawerItems from '../../../containers/menus/AdministrationDrawerItems';
import NavItem from '../NavItem/NavItem';
import { useParams } from 'react-router-dom'
import useProject from '../../../hooks/useProject';
import useIntl from '../../../hooks/useIntl';
import RawLink from '../../RawLink/RawLink';
import laikaBlue from '../../../images/laika-cms.png';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Alert } from '@material-ui/lab';
import { errorToString } from '../../../lib/util';

const useStyles = makeStyles(theme => ({
  leftPad: {
    paddingLeft: theme.spacing(2)
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  logo: {
    marginLeft: 10,
    marginRight: 10,
    height: 40,
    width: 40
  }
}))

let expanded = false

const ProjectsDrawerItems = props => {
  const [show, setShow] = useState(expanded)
  expanded = show

  const { project: projectId } = useParams()
  const { project, isLoading, error, logo } = useProject(projectId)
  const __ = useIntl()

  const classes = useStyles()

  if (isLoading) return <CircularProgress />
  if (error) return <Alert>{errorToString(error)}</Alert>
  if (!project) return null

  return (
    <>
      <RawLink className={classes.toolbar} to="/">
        <ListItem button>
          <ListItemAvatar>
            <Avatar
              alt="Logo"
              src={get(logo, 'attributes.sizes.thumbnail.url', laikaBlue)}
            />
          </ListItemAvatar>
          <ListItemText 
            primaryTypographyProps={{ variant: 'h4', color: 'primary' }} 
            primary={get(project, 'attributes.name', __`cmsTitle`)} />
        </ListItem>
      </RawLink>
      <Divider />

      <NavItem
        onClick={() => setShow(!expanded)}
        dense
        selected
        icon={<ArrowBackIcon />}
        exact
        text={__`back`} to={ROUTES.PROJECTS} />

      <NavItem
        exact
        icon={<SupervisorAccountIconOutline />}
        to={ROUTES.PROJECT.replace(':project', project.id)}
        text={__`projectDashboard`}
      />
      <NavItem
        exact
        icon={<InsertDriveFileIcon />}
        to={ROUTES.PROJECT_MEDIA_GALLERY.replace(':project', project.id)}
        text={__`files`}
      />
      <NavItem
        exact
        icon={<AudiotrackIcon />}
        to={ROUTES.PROJECT_SOUNDTRACK_OVERVIEW.replace(':project', project.id)}
        text={__`soundtracks`}
      />
      <NavItem
        exact
        icon={<ImageIcon />}
        to={ROUTES.PROJECT_IMAGES_OVERVIEW.replace(':project', project.id)}
        text={__`responsiveImages`}
      />
      <AdministrationDrawerItems projectId={project.id} />
    </>
  )
}

ProjectsDrawerItems.propTypes = {
  projects: propTypes.array.isRequired
}

export default ProjectsDrawerItems
