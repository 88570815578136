import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Table } from '@material-ui/core'
import { TableBody } from '@material-ui/core'
import { TableCell } from '@material-ui/core'
import { TableHead } from '@material-ui/core'
import { TableRow } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import Page from '../Page/Page'
import { Typography } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import RawLink from '../RawLink/RawLink'
import AddIcon from '@material-ui/icons/Add'
import { Dialog } from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { DialogContent } from '@material-ui/core'
import { DialogContentText } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none'
  }
})

class WebPushFeeds extends Component {
  constructor (props) {
    super()
    const { client, webPushFeeds } = props

    this.state = {
      deletePromptForfeedid: null
    }
  }

    handleDialogOpen = feedid => () => {
      this.setState({ deletePromptForfeedid: feedid })
    }

    handleDialogClose = () => {
      this.setState({ deletePromptForfeedid: null })
    }

    handleDialogCloseAndDelete = () => {
      throw new Error('Not yet implemented error.')
      // WebPushFeedsStore.deleteWebPushFeed(this.props.clientId, this.state.deletePromptForfeedid)
      // this.setState({ deletePromptForfeedid: null });
    }

    render () {
      const { client, webPushFeeds, classes } = this.props

      return (
        <Paper className={classes.root}>
          <RawLink to={`/clients/${this.props.clientId}/notifications/webpushfeeds/new`}>
            <Button variant="contained" color="primary" className={classes.button}>
              <AddIcon /> New
            </Button>
          </RawLink>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Edit</TableCell>
                <TableCell>Name</TableCell>
                <TableCell type="number">Id</TableCell>
                <TableCell>Url</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {webPushFeeds.map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableCell style={styles.editor} component="th" scope="row">
                      <IconButton onClick={this.handleDialogOpen(row.id)}>
                        <DeleteIcon color="secondary" />
                      </IconButton>
                      <RawLink to={`/clients/${client.id}/notifications/webpushfeeds/${row.id}/edit`}>
                        <IconButton>
                          <EditIcon color="primary" />
                        </IconButton>
                      </RawLink>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell numeric>{row.id}</TableCell>
                    <TableCell>{row.url}</TableCell>
                    <TableCell><Checkbox
                      checked={row.active}
                      style={{ pointerEvents: 'none' }}
                    /></TableCell>
                    <TableCell>{row.type}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <Dialog
            open={this.state.deletePromptForfeedid !== null}
            onClose={this.handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                            Let Google help apps determine location. This means sending anonymous location data to
                            Google, even when no apps are running.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogClose} color="primary">
                            Disagree
              </Button>
              <Button onClick={this.handleDialogCloseAndDelete} color="primary" autoFocus>
                            Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      )
    }
}

WebPushFeeds.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  webPushFeeds: PropTypes.array.isRequired
}

export default WebPushFeeds
