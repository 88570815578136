
import { bool, shape, string } from 'prop-types'
import { relationshipShape } from './json-api';

export const user = shape({
  attributes: shape({
    createdAt: string,
    description: string,
    email: string,
    enabled: bool,
    fname: string,
    fullName: string,
    integrations: shape({
      github: bool
    }),
    joined: string,
    lname: string,
    mname: string,
    role: string,
    updatedAt: string,
    username: string
  }),
  id: string,
  relationships: shape({
    client: relationshipShape,
    customFieldsPiece: relationshipShape,
    projects: relationshipShape
  }),
  type: string
})
