import React, { Component } from 'react'
import ClientDetailComponent from '../components/ClientDetails/ClientDetails'
import { compose } from 'redux'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { createSelector } from 'reselect'
import { withStyles } from '@material-ui/core/styles'
import { updateResource } from 'redux-json-api'

const matStyle = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
})

const stateSelector = createSelector(
  state => state.property1,
  (property1) => ({ property1 })
)

const enhance = compose(
  withStyles(matStyle, { withTheme: true }),
  connect(
    stateSelector,
    dispatch => ({
      updateClientsData: ({ formData, clientId }) => dispatch(
        updateResource({
          id: clientId,
          type: 'clients',
          attributes: formData
        }))
    })
  )
)

export default enhance(ClientDetailComponent)
