import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import { readEndpoint } from 'redux-json-api';
import { errorToString } from '../lib/util';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import useJsonApiIsLoading from './useJsonApiIsLoading';
import useJsonApiError from './useJsonApiError';

const useProjects = () => {
  const userId = useSelector(state => get(state, 'auth.authData.user.data.id'))
  const endpoint = `users/${userId}/relationships/projects?include=logo`
  const dispatch = useDispatch()

  const isLoading = useJsonApiIsLoading(endpoint)
  const error = useJsonApiError(endpoint)

  const projects = useSelector(state => get(state, 'api.projects.data'))

  useEffect(() => {
    if (!isLoading && !error) dispatch(readEndpoint(endpoint))
  }, [])

  return { isLoading, error, projects }
};

export default useProjects
