import { Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import get from 'lodash/get';
import ProptTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import Page from '../Page/Page';
import styles from './Settings.module.css';

const matStyles = theme => ({
  padded: {
    padding: theme.spacing(2)
  }
})

class Settings extends Component {
  constructor (props) {
    super()
    this.state = {
      cmsHost: props.cmsHost
    }
  }

    handleChange = name => event => {
      this.setState({
        [name]: event.target.value
      })
    };

    componentWillMount () {
    }

    componentWillUnmount () {
    }

    componentDidUpdate = (nextProps) => {
    }

    initSettings = () => {

    }

    handleSubmit = async () => {
      if (this.state.cmsHost.endsWith('/')) {
        window.alert(`API host url: ${this.state.cmsHost} must not end with a "/"`)
        return false
      }

      await this.props.handleSave(this.state)
    }

    hardReset = () => {
      if (window.confirm('Bust all cache?')) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (const registration of registrations) {
            try {
              registration.unregister()
            } catch (err) {
              console.error(err)
            }
          }
        }).catch(function (err) {
          window.alert(err)
        }).then(function () {
          return window.indexedDB.databases()
        }).then(function (r) {
          window.localStorage.clear()
          window.sessionStorage.clear()
          var cookies = window.document.cookie.split(';')
          for (let i = 0; i < cookies.length; i++) {
            var cookie = cookies[i]
            var eqPos = cookie.indexOf('=')
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
          }
          for (let i = 0; i < r.length; i++) { window.indexedDB.deleteDatabase(r[i].name) }
          window.location.reload(true)
        }).catch(err => {
          window.alert(err)
        })
      }
    }

    render () {
      const { classes, messages } = this.props
      return (
        <Page title={get(messages, 'settings.message')}>
          <Helmet>
            <title>{get(messages, 'settings.message')}</title>
            <meta name="description" content={get(messages, 'settings.message')} />
          </Helmet>
          <Container>
            <form onSubmit={this.handleSubmit} className="Settings">

              <small>{process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION}</small>

              <h1>{get(messages, 'settings.message')}</h1>

              <h2>Connection</h2>

              <h5>CMS url:</h5>

              <div>
                <TextField
                  id="cms-url"
                  label="CMS url"
                  className={styles.textField}
                  value={this.state.cmsHost}
                  onChange={this.handleChange('cmsHost')}
                  margin="normal" />
              </div>

              <Button type="submit" color="primary" variant="contained" className={''}>
                {get(messages, 'save.message')}
              </Button>

              <Box mt={1} />

              <Button color="secondary" type="button" onClick={this.hardReset} variant="contained" className={''}>
                Hard Reset
              </Button>

            </form>
          </Container>

        </Page>
      )
    }
}

Settings.propTypes = {
  handleSave: ProptTypes.func.isRequired
}

const enhancer = compose(
  withStyles(matStyles, { withTheme: true })
)

export default enhancer(Settings)
