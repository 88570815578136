import React, { Component } from 'react'
import AdministrationDrawerItemsComponent from '../../components/menus/AdministrationDrawerItems/AdministrationDrawerItems'
import { compose } from 'redux'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { createSelector } from 'reselect'
import withInternationalization from '../../hocs/Internationalization'

const stateSelector = createSelector(
  state => state.property1,
  (property1) => ({ property1 })
)

const enhance = compose(
  connect(
    stateSelector,
    dispatch => ({

    })
  ),
  withInternationalization()
)

const enhancedComponent = enhance(AdministrationDrawerItemsComponent)

enhancedComponent.propTypes = {

}

enhancedComponent.defaultProps = {

}

export default enhancedComponent
