import React, { Component } from 'react'
import './Login.css'
import { Paper } from '@material-ui/core'
import { FormControl } from '@material-ui/core'
import { FormControlLabel } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Input } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import Auth from '../../containers/Auth'

class Login extends Component {
  constructor (props) {
    super()
    this.state = {
    }
  }

  componentWillMount () {
  }

  componentWillUnmount () {
  }

  render () {
    return (
      <div className="Login">
        <Auth type="login" />
      </div>
    )
  }
}

export default Login
