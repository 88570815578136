import React, { Component } from 'react'
import ClientDetailComponent from '../components/UsersGrid/UsersGrid'
import { compose } from 'redux'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { createSelector } from 'reselect'
import { withStyles } from '@material-ui/core/styles'
import { updateResource, readEndpoint } from 'redux-json-api'
import get from 'lodash/get'

const matStyle = (theme) => ({

})

const stateSelector = createSelector(
  (state, { clientId }) => get(state, 'api.users.data', [])
    .filter(user => get(user, 'relationships.client.data.id') === clientId),
  (users) => ({ users })
)

const enhance = compose(
  withStyles(matStyle, { withTheme: true }),
  connect(
    stateSelector,
    (dispatch, { clientId }) => ({
      getUsers: () => {
        if (!clientId) throw new Error('Client id is falsy')
        dispatch(
          readEndpoint(`clients/${clientId}/relationships/users`)
        )
      }
    })
  )
)

const enhanced = enhance(props => console.log(props) || <ClientDetailComponent {...props} />)

export default enhanced
