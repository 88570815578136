import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AppleIcon from '@material-ui/icons/Apple';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import HomeOutlined from '@material-ui/icons/HomeOutlined';
import PeopleIcon from '@material-ui/icons/People';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ShopIcon from '@material-ui/icons/Shop';
import UpdateIcon from '@material-ui/icons/Update';
import get from 'lodash/get';
import propTypes from 'prop-types';
import React from 'react';
import * as TYPES from '../../../types';
import RawLink from '../../RawLink/RawLink';
import GlobalDrawerItems from '../GlobalDrawerItems/GlobalDrawerItems';

const icons = {
  home: HomeOutlined,
  audio: AudiotrackIcon,
  app: PhoneIphoneIcon,
  images: PhotoLibraryIcon,
  attach: AttachFileIcon,
  apple: AppleIcon,
  googlePlay: ShopIcon,
  people: PeopleIcon,
  accountTree: AccountTreeIcon,
  update: UpdateIcon
}

const CustomDrawerItems = props => {
  const { messages, user, menuItems } = props

  console.log('menuItems', menuItems)

  return <List>
    {
      menuItems.map((item, i) => {
        const C = item.icon && icons[item.icon]

        let title = item.title

        if (item.external) {
          title += ` (${get(messages, 'external.message', 'External')})`
        }

        return (
          <RawLink external={item.external} key={i} to={item.url}>
            <ListItem button title={item.description}>
              {C ? <ListItemIcon><C /></ListItemIcon> : null}
              <ListItemText primary={title} />
            </ListItem>
          </RawLink>
        )
      })
    }
    <GlobalDrawerItems user={user} messages={messages} />
  </List>
}

CustomDrawerItems.defaultProps = {
  messages: TYPES.messages,
  user: TYPES.user,
  menuItems: propTypes.any
}

export default CustomDrawerItems;
