import React, { Component } from 'react'
import WebPushFeedsComponent from '../components/WebPushFeeds/WebPushFeeds'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { withStyles } from '@material-ui/core/styles'
import FullScreenLoader from '../components/FullScreenLoader/FullScreenLoader'
import FullScreenError from '../components/FullScreenError/FullScreenError'
import { arrayForce } from '../lib/util'
import get from 'lodash/get'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none'
  }
})

const clientSelector = createSelector(
  (state, props) => get(state, 'api.clients.data', []),
  (state, props) => props.clientId,
  (clients, clientId) => {
    return clients.find(client => client.id === clientId)
  }
)

const stateSelector = createSelector(
  clientSelector,
  state => arrayForce(state.webPush.feeds),
  (client, webPushFeeds) => ({
    client, webPushFeeds
  })
)

const enhance = compose(
  withStyles(styles),
  connect(
    stateSelector,
    dispatch => ({

    })
  ),
  Component => props => {
    if (props.isLoading) return <FullScreenLoader text="Loading feeds." />
    else if (props.error) return <FullScreenError text="Unable to load feeds." />
    else return <Component {...props} />
  }
)

const enhancedComponent = enhance(WebPushFeedsComponent)

enhancedComponent.propTypes = {
  clientId: PropTypes.string.isRequired
}

export default enhancedComponent
