import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core'
import { AppBar } from '@material-ui/core'
import { Toolbar } from '@material-ui/core'
import { CssBaseline } from '@material-ui/core'
import { List } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { Hidden } from '@material-ui/core'
import { Divider } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { ListItem } from '@material-ui/core'
import { ListItemIcon } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import MailIcon from '@material-ui/icons/Mail'
import NavBar from '../../containers/Navbar'
import DrawerItems from '../../containers/menus/DrawerItems'
import { LinearProgress } from '@material-ui/core'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import * as UI from '../../constants/ui'
import DrawerRoutes from '../menus/DrawerRoutes/DrawerRoutes';

const drawerWidth = UI.DRAWER_WIDTH

const styles = theme => console.log(theme) || ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  wrapper: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#F4F6F8',
    minHeight: '100vh'
  }
})

class Page extends React.Component {
  constructor() {
    super()
    this.state = {
      mobileOpen: false
    }
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }))
  };

  render() {
    const { classes, theme, isLoading, icons } = this.props

    return (
      <div className={classes.root}>
        <CssBaseline />
        <NavBar icons={icons} title={this.props.title} handleDrawerToggle={this.handleDrawerToggle} />
        <div className={classes.wrapper}>
          <nav className={classes.drawer}>
            {/* The implementation can be swap with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={this.props.container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                <DrawerRoutes />
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                <DrawerRoutes />
              </Drawer>
            </Hidden>
          </nav>
          <div className={classes.content}>
            <div className={classes.toolbar} />
            <main>
              <ErrorBoundary>
                {this.props.children}
              </ErrorBoundary>
            </main>
          </div>
        </div>

      </div >
    )
  }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  icons: PropTypes.any
}

export default withStyles(styles, { withTheme: true })(Page)
