import factuur from '../images/factuur.jpg'
import kostenindicatie from '../images/kostenindicatie.jpg'
import kwitantie from '../images/kwitantie.jpg'
import offerte from '../images/offerte.jpg'

export default [
  {
    key: 'factuur',
    name: 'Factuur',
    image: factuur
  },
  {
    key: 'kwitantie',
    name: 'Kwitantie',
    image: kwitantie
  },
  {
    key: 'kostenindicatie',
    name: 'Kostenindicatie',
    image: kostenindicatie
  },
  {
    key: 'offerte',
    name: 'Offerte',
    image: offerte
  }
]
