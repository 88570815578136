import get from 'lodash/get'
import { JWT_TOKEN } from '../../constants/settings'
import { BASE_URL } from '../../constants/api'

const fetch = window.fetch

const noJwtError = { title: 'No Validation token', detail: 'Please retry to login.', code: 'no-jwt-token', test: 'test' }

const getJwtToken = async () => {
  const { settingGet } = await import('../redux/util')
  const jwtToken = await settingGet(JWT_TOKEN)
  if (!jwtToken) throw noJwtError
  else return jwtToken
}

/**
 *
 * @param {(url, data) => Promise<string>} fetchRequest
 * @returns {(url, data) => Promise<string>}
 *
 * Takes a simple async fetch function as it's only parameter and returns a function that handles https://jsonapi.org/format/ json responses and errors.
 * Provides consistency, errors are always thrown in this format https://jsonapi.org/format/#errors.
 * The returned function always returns "{data: <actual json response>}". See: https://jsonapi.org/format/.
 */

export const jsonDataRequestFunctionFactory = (fetchRequest) => {
  return async (url, data) => {
    let response = null
    let json = null
    try {
      response = await fetchRequest(url, data)
      json = await response.json()
      if (!response.ok) throw new Error(response.status + ' ' + response.statusText)
    } catch (originalError) {
      const error = {
        errors: get(json, 'errors', [{
          title: get(originalError, 'title', 'Error'),
          detail: get(originalError, 'message') || get(originalError, 'detail'),
          source: get(originalError, 'source'),
          status: get(originalError, 'status'),
          code: get(originalError, 'code', 'fetch-error')
        }]),
        meta: {
          response, url, requestData: data, originalError
        },
        data: json
      }
      throw error
    }
    return { data: json, meta: { response, url, requestData: data } }
  }
}

export const post = async (endpoint, data) => {
  const { settingGet } = await import('../redux/util')
  const cmsHost = await settingGet('cmsHost')
  const base = new URL(BASE_URL, cmsHost).href
  const url = new URL(endpoint, base).href
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'X-Apicache-Bypass': true
    },
    method: 'POST',
    credentials: 'omit', // include, *same-origin, omit
    mode: 'cors',
    body: data ? JSON.stringify(data) : data
  })
  return response
}

export const postJson = jsonDataRequestFunctionFactory(post)

export const getWithJWT = async (endpoint, data) => {
  const { settingGet } = await import('../redux/util')
  const [jwtToken, cmsHost] = await Promise.all([
    getJwtToken(),
    settingGet('cmsHost')
  ])
  const base = new URL(BASE_URL, cmsHost).href
  const url = new URL(endpoint, base).href
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `JWT ${jwtToken}`,
      'X-Apicache-Bypass': true
    },
    method: 'GET',
    credentials: 'omit', // include, *same-origin, omit
    mode: 'cors',
    body: data ? JSON.stringify(data) : undefined
  })
  return response
}

export const getJsonWithJWT = jsonDataRequestFunctionFactory(getWithJWT)

export const postWithJWT = async (endpoint, data) => {
  const { settingGet } = await import('../redux/util')
  const [jwtToken, cmsHost] = await Promise.all([
    getJwtToken(),
    settingGet('cmsHost')
  ])
  const base = new URL(BASE_URL, cmsHost).href
  const url = new URL(endpoint, base).href
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `JWT ${jwtToken}`,
      'X-Apicache-Bypass': true
    },
    method: 'POST',
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit', // include, *same-origin, omit
    mode: 'cors',
    body: data ? JSON.stringify(data) : data
  })
  return response
}

export const postJsonWithJWT = jsonDataRequestFunctionFactory(postWithJWT)

export const putWithJWT = async (endpoint, data) => {
  const { settingGet } = await import('../redux/util')
  const [jwtToken, cmsHost] = await Promise.all([
    getJwtToken(),
    settingGet('cmsHost')
  ])
  const base = new URL(BASE_URL, cmsHost).href
  const url = new URL(endpoint, base).href
  return fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `JWT ${jwtToken}`,
      'X-Apicache-Bypass': true
    },
    method: 'PUT',
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit', // include, *same-origin, omit
    mode: 'cors',
    body: data ? JSON.stringify(data) : data
  })
}

export const putJsonWithJWT = jsonDataRequestFunctionFactory(putWithJWT)

export const deleteWithJWT = async (endpoint, data) => {
  const { settingGet } = await import('../redux/util')
  const [jwtToken, cmsHost] = await Promise.all([
    getJwtToken(),
    settingGet('cmsHost')
  ])
  const base = new URL(BASE_URL, cmsHost).href
  const url = new URL(endpoint, base).href
  return fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `JWT ${jwtToken}`,
      'X-Apicache-Bypass': true
    },
    method: 'DELETE',
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit', // include, *same-origin, omit
    mode: 'cors',
    body: data ? JSON.stringify(data) : data
  })
}
