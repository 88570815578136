import React, { Component } from 'react'
import styles from './NewClient.module.css'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { FormControlLabel } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { sendWebPushNotification } from '../../lib/notifications/broadcast'

const rmEmptyString = obj => Object.keys(obj).forEach(key => {
  if (obj[key] === '') delete obj[key]
})

class NewClient extends Component {
  constructor (props) {
    super()
    this.state = {
      data: {
        fname: '',
        mname: '',
        lname: '',
        tel: '',
        name: '',
        url: ''
      }
    }
  }

  componentWillMount () {
  }

  componentWillUnmount () {
  }

    handleSubmit = async e => {
      e.preventDefault()
      const data = this.state.data
      rmEmptyString(data)
      try {
        await this.postClient({ data })
        this.setState({ message: 'Done!' })
      } catch (err) {
        this.setState({ message: err.message })
      }
    }

    handleFormChange = name => e => {
      const data = { ...this.state.data }
      data[name] = e.target.value
      this.setState({ data })
    }

    handleFormCheckboxChange = name => e => {
      const data = { ...this.state.data }
      data[name] = e.target.checked
      this.setState({ data })
    }

    render () {
      return (
        <div className="NotificationSender">
          <Paper className={styles.formWrapper}>
            <form onSubmit={this.handleSubmit} className={styles.form}>
              <TextField
                id="name"
                label="Name"
                required
                className={styles.name}
                value={this.state.data.name}
                onChange={this.handleFormChange('name')}
                margin="normal"
                type="text"
              />
              <TextField
                id="fname"
                label="First name"
                className={styles.title}
                value={this.state.data.fname}
                onChange={this.handleFormChange('fname')}
                margin="normal"
                type="text"
              />
              <TextField
                id="mname"
                label="Middle name"
                className={styles.title}
                value={this.state.data.mname}
                onChange={this.handleFormChange('mname')}
                margin="normal"
                type="text"
              />
              <TextField
                id="lname"
                label="Last name"
                className={styles.title}
                value={this.state.data.lname}
                onChange={this.handleFormChange('lname')}
                margin="normal"
                type="text"
              />
              <TextField
                id="tel"
                label="Telephone number"
                className={styles.tel}
                value={this.state.data.tel}
                onChange={this.handleFormChange('tel')}
                margin="normal"
                type="tel"
              />
              <TextField
                id="url"
                label="Url"
                value={this.state.data.url}
                onChange={this.handleFormChange('url')}
                className={styles.url}
                type="url"
                margin="normal"
              />
              <br /><br />
              <Button variant="contained" type="submit">Submit</Button>
            </form>
            <Typography color="secondary">{this.state.message}</Typography>
          </Paper>
        </div>
      )
    }
}

NewClient.propTypes = {
  postClient: PropTypes.func.isRequired
}

export default NewClient
