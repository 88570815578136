import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import * as TYPES from '../../../types';
import { List } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import NavItem from '../NavItem/NavItem'

const GlobalDrawerItems = props => {
  const { messages, user } = props

  return (
    <List disablePadding>
      {user && (
        <NavItem icon={<AccountBoxIcon />} to={`/user/${user.id}`} text={get(messages, 'account.message', 'Account')} />
      )}

      <NavItem icon={<SettingsIcon />} to="/settings" text={get(messages, 'settings.message', 'Settings')} />
    </List>
  )
}

GlobalDrawerItems.propTypes = {
  messages: TYPES.messages,
  user: TYPES.user
}

GlobalDrawerItems.defaultProps = {

}

export default GlobalDrawerItems;
