import { createElement } from 'react'
import some from 'lodash/some'
import get from 'lodash/get'

const isLoaded = value => {
  return value !== undefined
}

const isEmpty = value => {
  return Array.isArray(value) && value.length === 0
}

// HOC that shows a component while condition is true
export const renderWhile = (condition, Component) => {
  return (BaseComponent) => {
    return (props) => {
      if (condition(props)) {
        return createElement(Component, props)
      } else {
        return createElement(BaseComponent, props)
      }
    }
  }
}
// HOC that shows loading spinner Component while list of propNames are loading
export const renderWhileLoading = (propNames, Component) => {
  if (typeof propNames === 'string') propNames = [propNames]
  return renderWhile((props) => some(propNames, name => !isLoaded(get(props, name))), Component)
}
// HOC that shows a Component while any of a list of props isEmpty
export const renderIfEmpty = (propNames, Component) => {
  if (typeof propNames === 'string') propNames = [propNames]
  return renderWhile(
    // Any of the listed prop name correspond to empty props (supporting dot path names)
    (props) => some(propNames, (name) => {
      const propValue = get(props, name)
      return isLoaded(propValue) && isEmpty(propValue)
    }), Component)
}
