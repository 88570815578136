import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import withInternationalization from '../hocs/Internationalization'
import Page from '../components/Page/Page'
import { Typography } from '@material-ui/core'

const NotFoundPageComponent = props => {
  return <Page title={get(props, 'messages.notFound.message')}>
    <Typography variant="h5">{get(props, 'messages.notFound.message')}</Typography>
  </Page>
}

const stateSelector = createSelector(
  state => state.property1,
  (property1) => ({ property1 })
)

const enhance = compose(
  withInternationalization(),
  connect(
    stateSelector,
    dispatch => ({

    })
  )
)

const enhancedComponent = enhance(NotFoundPageComponent)

enhancedComponent.propTypes = {

}

enhancedComponent.defaultProps = {

}

export default enhancedComponent
