import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import styles from './FullScreenLoader.module.css'

const FullScreenLoader = props => {
  return (
    <div className={styles.filler}>
      <CircularProgress />
      <h2 className={styles.text}>{props.text}</h2>
    </div>
  )
}

export default FullScreenLoader
