import React, { Component } from 'react'
import NotificationsComponent from '../components/Notifications/Notifications'
import { compose } from 'redux'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { createSelector } from 'reselect'
import { withStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import FullScreenLoader from '../components/FullScreenLoader/FullScreenLoader'
import FullScreenError from '../components/FullScreenError/FullScreenError'
import { fetchWebPushFeeds } from '../actions/esserver/web-push/feeds'
import { fetchWebPushClientSubscriptions } from '../actions/esserver/web-push/subscriptions'

const styles = theme => ({
  paper: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
})

const stateSelector = createSelector(
  (state, props) => get(state.webPush.feeds.feedsByClientId, [props.clientId], null),
  (state, props) => get(state.webPush.subscriptions.subscriptionCountByClientId, [props.clientId], null),
  (feeds, subscribers) => ({ feeds, subscribers, subscriberCount: +get(subscribers, 'count'), feedsCount: +get(feeds, 'feeds.length') })
)

const enhance = compose(
  connect(
    stateSelector,
    (dispatch, { clientId }) => ({
      fetchWebPushFeeds: () => dispatch(fetchWebPushFeeds({ clientId })),
      fetchWebPushClientSubscriptions: () => dispatch(fetchWebPushClientSubscriptions({ clientId }))
    })
  ),
  withStyles(styles),
  Component => props => {
    if (!get(props, 'feeds')) {
      props.fetchWebPushFeeds()
    } else if (!get(props, 'subscribers')) {
      props.fetchWebPushClientSubscriptions()
    } else return <Component {...props} />
    return null
  }
)

export default enhance(NotificationsComponent)
