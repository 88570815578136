import React, { Component } from 'react'
import ClientComponent from '../components/Client/Client'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { createSelector } from 'reselect'
import FullScreenError from '../components/FullScreenError/FullScreenError'
import FullScreenLoader from '../components/FullScreenLoader/FullScreenLoader'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import { arrayForce } from '../lib/util'
import withInternationalization from '../hocs/Internationalization'

const styles = theme => ({
  hidden: {
    visibility: 'hidden'
  }
})

const stateSelector = createSelector(
  state => get(state, 'api.clients.data', []),
  (state, props) => props.match.params.clientId,
  (clients, clientId) => ({
    client: clients.find(client => client.id === clientId)
  })
)

const enhance = compose(
  withRouter,
  withInternationalization(),
  withStyles(styles),
  connect(
    stateSelector,
    dispatch => ({

    })
  ),
  Component => props => {
    if (props.isLoading) return <FullScreenLoader />
    else if (props.error) return <FullScreenError text={get(props, 'error.message', props.error)} />
    else return <Component {...props} />
  }
)

export default enhance(ClientComponent)
