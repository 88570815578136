import { withStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { readEndpoint } from 'redux-json-api'
import { createSelector } from 'reselect'
import DrawerItemsComponent from '../../components/menus/DrawerItems/DrawerItems'
import withInternationalization from '../../hocs/Internationalization'
import Settings from '../../lib/settings/Settings'

const styles = theme => ({
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  logo: {
    marginLeft: 10,
    marginRight: 10,
    height: 40,
    width: 40
  }
})

const stateSelector = createSelector(
  state => Settings.getAllSettings(),
  state => get(state, 'auth.authData.user.data'),
  state => get(state, 'api.pieces.data', []),
  state => state.apiMeta.status,
  (settings, user, pieces, apiMetaStatus) => {
    const customPieceId = get(user, 'relationships.customFieldsPiece.data.id')
    const customUserFieldsPiece = pieces.find(piece => piece.id === customPieceId)
    const menuId = get(customUserFieldsPiece, 'attributes.structure.settings.menu.id')
    const menuPiece = pieces.find(piece => piece.id === menuId)
    console.log('menuId', menuId)
    return {
      settings,
      customPieceId,
      menuId,
      user,
      customUserFieldsPiece,
      menuPiece,
      apiMetaStatus
    }
  }
)

const withCustomUserPiece = Component => props => {
  const { fetchCustomUserPiece, apiMetaStatus, fetchMenuPiece, menuId, user } = props

  useEffect(() => {
    if (user && !menuId && !(`users/${user.id}/relationships/customFieldsPiece` in apiMetaStatus)) {
      fetchCustomUserPiece(user.id)
        .catch(err => {
          // do not display error
          console.log(err)
        }).then(() => {

        })
    }
  }, [user && user.id])

  useEffect(() => {
    if (user && menuId && !(`pieces/${menuId}` in apiMetaStatus)) {
      fetchMenuPiece(menuId)
        .catch(err => {
          // do not display error
          console.log(err)
        }).then(() => {

        })
    }
  }, [menuId])

  return <Component {...props} />
}

const enhance = compose(
  withInternationalization(),
  withStyles(styles, { withTheme: true }),
  connect(
    stateSelector,
    dispatch => ({
      fetchCustomUserPiece: async userId => {
        await dispatch(readEndpoint(`users/${userId}/relationships/customFieldsPiece`))
      },
      fetchMenuPiece: async menuPieceId => {
        await dispatch(readEndpoint(`pieces/${menuPieceId}`))
      }
    })
  ),
  withCustomUserPiece
)

export default enhance(DrawerItemsComponent)
