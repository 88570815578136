import React, { Component } from 'react'
import './Register.css'
import Auth from '../Auth/Auth'

class Register extends Component {
  constructor (props) {
    super()
    this.state = {
    }
  }

  componentWillUnmount () {
  }

  render () {
    return (
      <div className="Register">
        <Auth type="register" />
      </div>
    )
  }
}

export default Register
