import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import { readEndpoint } from 'redux-json-api';
import { errorToString } from '../lib/util';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import useJsonApiIsLoading from './useJsonApiIsLoading';
import useJsonApiError from './useJsonApiError';

const useProject = (projectId) => {
  const endpoint = `projects/${projectId}?include=logo`
  const dispatch = useDispatch()

  const isLoading = useJsonApiIsLoading(endpoint)
  const error = useJsonApiError(endpoint)

  const project = useSelector(state => 
    get(state, 'api.projects.data', [])
      .find(p => p.id === projectId))

  const logoId = get(project, ['relationships', 'logo', 'data', 'id'])
  const logo = useSelector(state =>
    get(state, 'api.images.data', [])
      .find(image => image.id === logoId))

  useEffect(() => {
    if (projectId && !isLoading && !error) dispatch(readEndpoint(endpoint))
  }, [projectId])

  return { isLoading, error, project, logo }
};

export default useProject
