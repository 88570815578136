import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core'
import { AppBar } from '@material-ui/core'
import { Toolbar } from '@material-ui/core'
import { CssBaseline } from '@material-ui/core'
import { List } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { Hidden } from '@material-ui/core'
import { Divider } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { Menu } from '@material-ui/core'
import { MenuItem } from '@material-ui/core'
import { ListItem } from '@material-ui/core'
import { ListItemIcon } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import MailIcon from '@material-ui/icons/Mail'
import get from 'lodash/get'
import withInternationalization from '../../hocs/Internationalization'
import * as UI from '../../constants/ui'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import nlFlag from 'svg-country-flags/svg/nl.svg'
import enFlag from 'svg-country-flags/svg/us.svg'

const languages = {
  nl: {
    icon: nlFlag,
    name: 'Nederlands'
  },
  en: {
    icon: enFlag,
    name: 'English'
  }
}

const drawerWidth = UI.DRAWER_WIDTH

const styles = theme => ({
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  title: {
    flexGrow: 1
  }
})

const NavBar = props => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleLanguagesClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleLanguagesClose = () => {
    setAnchorEl(null)
  }

  const handleLanguageSelect = lang => () => {
    handleLanguagesClose(null)
    props.switchLanguage(lang)
  }

  const { classes, language, messages, icons, icon, logout, title, handleDrawerToggle } = props

  return (<AppBar position="absolute" className={classes.appBar} color="transparent" elevation="0">
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h3" color="primary" noWrap className={classes.title}>
        {title}
      </Typography>
      <IconButton onClick={handleLanguagesClick}>
        <img height={20} alt={get(messages, 'languageSelector.message')} src={icon} />
      </IconButton>
      <IconButton
        aria-label={get(messages, 'logout.messages')}
        onClick={logout}
        color="inherit"
      >
        <ExitToAppIcon titleAccess={get(messages, 'logout.messages')} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleLanguagesClose}
      >
        {Object.keys(languages).map(key => {
          const { name: optionLanguageName, icon: optionLanguageIcon } = languages[key] || {}

          return (
            <MenuItem onClick={handleLanguageSelect(key)} key={key}>
              <img height={20} alt={optionLanguageName} src={optionLanguageIcon} />
              <div style={{ marginLeft: 10 }} />{optionLanguageName}
            </MenuItem>
          )
        })}
      </Menu>
      {icons || null}
    </Toolbar>
  </AppBar>)
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icons: PropTypes.any
}

export default withInternationalization()(withStyles(styles)(NavBar))
