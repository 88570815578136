import { Card, Icon, Button, Box } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import get from 'lodash/get';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Surface from 'react-surfaces';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Page from '../Page/Page';
import RawLink from '../RawLink/RawLink';
import useIntl from '../../hooks/useIntl';
import useProjects from '../../hooks/useProjects';
import LoadingContainer from '../loading/LoadingContainer/LoadingContainer';
import * as ROUTES from '../../constants/routes'
import { useSelector } from 'react-redux'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const useStyles = makeStyles(theme => ({
  padded: {
    padding: theme.spacing(2)
  },
  backgroundSurface: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  card: {
    position: 'relative',
    minHeight: 400
  },
  cardContent: {
    position: 'relative'
  }
}))

const Hyperlink = props => {
  const { background, title, description, textColor, action, height } = props
  const classes = useStyles()
  const theme = useTheme()

  const [hover, setHover] = useState(false)
  const hoverEnable = setHover.bind(null, true)
  const hoverDisable = setHover.bind(null, false)

  return <RawLink to={action}>
    <Card className={classes.card} onMouseEnter={hoverEnable} onMouseLeave={hoverDisable} onTouchStart={hoverEnable} onTouchEnd={hoverDisable}>
      {get(background, 'backgroundType') === 'surface' && (
        <div className={classes.backgroundSurface}>
          <Surface
            variant={+get(background, 'backgroundValue')}
            color1={hover ? theme.palette.secondary.main : theme.palette.primary.main} // defaults to #e52421
            color2={hover ? theme.palette.primary.main : theme.palette.secondary.main} // defaults to #0000ff
            transitionDuration={'2s'} // optional
            transitionDelay={'0s'} // optional
            transitionTimingFunction={'ease'} // optional
            title={title}
          />
        </div>
      )}
      <CardContent className={classes.cardContent} style={{ color: textColor, height }}>
        <Typography gutterBottom variant="h5" component="h2" color="inherit">
          {title}
        </Typography>
        <Typography variant="body2" component="p" color="inherit">
          {description}
        </Typography>
      </CardContent>
    </Card>
  </RawLink>
}

const renderDashboardItem = item => {
  const { action, actionType, background, description, size, title, textColor, height } = item

  switch (actionType) {
    case 'hyperlink':
      return <Hyperlink action={action} actionType={actionType} background={background} height={height} description={description} size={size} title={title} textColor={textColor} />
    default:
      return null
  }
}

const Project = ({ project }) => {

  console.log('test')

  const logoId = get(project, ['relationships', 'logo', 'data', 'id'])
  const image = useSelector(state =>
    get(state, 'api.images.data', [])
      .find(image => image.id === logoId))

  return <RawLink to={ROUTES.PROJECT.replace(':project', project.id)}>
    <Card style={{ height: '100%' }}>
      <CardContent>
        {image && <img src={image.attributes.sizes.thumbnail.url} alt={project.attributes.name + ' logo'} />}
        <Typography gutterBottom variant="h5" component="h2" color="inherit">
          {project.attributes.name}
        </Typography>
        <Typography variant="body2" component="p" color="inherit">
          {project.attributes.description}
        </Typography>
      </CardContent>
    </Card>
  </RawLink>
}

const Dashboard = props => {
  const { messages, dashboardPiece } = props

  const classes = useStyles()

  console.log('dashboardPiece', dashboardPiece)

  const customItems = get(dashboardPiece, 'attributes.structure.items', [])

  const { isLoading: isProjectsLoading, error, projects } = useProjects()

  const __ = useIntl()

  return (
    <Page title={get(messages, 'dashboard.message')}>
      <Helmet>
        <title>{get(messages, 'dashboard.message')}</title>
        <meta name="description" content={get(messages, 'dashboard.message')} />
      </Helmet>
      <div className={classes.padded}>
        <Grid container spacing={2}>
          {customItems.map((item, i) => {
            const { size } = item
            return (
              <Grid key={i} item xs={Math.min(12, size * 3)} sm={Math.min(12, size * 2)} md={size}>
                <ErrorBoundary>
                  {renderDashboardItem(item)}
                </ErrorBoundary>
              </Grid>
            )
          })}
        </Grid>
      </div>
      <div className={classes.padded}>
        <Typography variant="h3">{__`projects`}</Typography>
      </div>
      <div className={classes.padded}>

        <LoadingContainer isLoading={isProjectsLoading}>
          <Grid container spacing={2}>
            {projects && projects.slice(0, 3).map((project, i) => {
              return (
                <Grid key={i} item xs={12} md={4}>
                  <RawLink to={ROUTES.PROJECT.replace(':project', project.id)}>
                    <ErrorBoundary>
                      <Project project={project} />
                    </ErrorBoundary>
                  </RawLink>
                </Grid>
              )
            })}
          </Grid>
          {projects && projects.length > 3 &&
            <Grid justify="flex-end" container>
              <Grid item>
                <RawLink to={ROUTES.PROJECTS}>
                  <Button color="primary" endIcon={<ArrowRightAltIcon />}>{__`viewAllProjects`}</Button>
                </RawLink>
              </Grid>
            </Grid>}
        </LoadingContainer>

      </div>
    </Page>
  )
}

export default Dashboard
