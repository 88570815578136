import { connect } from 'react-redux'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import { updateLanguage } from '../actions/internationalization'
import NavbarComponent from '../components/NavBar/NavBar'
import Settings from '../lib/settings/Settings'
import * as locales from '../locales'
import { logout } from '../actions/esserver/auth';

const stateSelector = createSelector(
  state => state.property1,
  (property1) => ({ property1 })
)

const enhance = compose(
  connect(
    stateSelector,
    dispatch => ({
      switchLanguage: async language => {
        console.log('switchLanguage', language)
        const action = updateLanguage(await locales[language]())
        Settings.persist({ language })
        return dispatch(action)
      },
      logout: () => dispatch(logout())
    })
  )
)

const enhancedComponent = enhance(NavbarComponent)

enhancedComponent.propTypes = {

}

enhancedComponent.defaultProps = {

}

export default enhancedComponent
