import { shape, string, objectOf } from 'prop-types';

export const messageShape = shape({
  message: string
})

export const messages = objectOf(messageShape)

export const internationalizationShape = shape({
  icon: string,
  language: string,
  name: string,
  messages: messages
})

