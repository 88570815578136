import React, { Component, useEffect } from 'react'
import DashboardComponent from '../components/Dashboard/Dashboard'
import { compose } from 'redux'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { createSelector } from 'reselect'
import Settings from '../lib/settings/Settings'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import withInternationalization from '../hocs/Internationalization'
import { readEndpoint } from 'redux-json-api'

const stateSelector = createSelector(
  state => Settings.getAllSettings(),
  state => get(state, 'auth.authData.user.data'),
  state => get(state, 'api.pieces.data', []),
  (settings, user, pieces) => {
    const customPieceId = get(user, 'relationships.customFieldsPiece.data.id')
    const customUserFieldsPiece = pieces.find(piece => piece.id === customPieceId)
    const dashboardId = get(customUserFieldsPiece, 'attributes.structure.settings.dashboard.id')
    const dashboardPiece = pieces.find(piece => piece.id === dashboardId)
    console.log('menuId', dashboardId)
    return {
      settings,
      customPieceId,
      menuId: dashboardId,
      user,
      customUserFieldsPiece,
      dashboardPiece
    }
  }
)

const withCustomUserPiece = Component => props => {
  const { fetchCustomUserPiece, fetchDashboardPiece, menuId, user } = props

  useEffect(() => {
    if (user) {
      fetchCustomUserPiece(user.id)
        .catch(err => {
          // do not display error
          console.log(err)
        }).then(() => {

        })
    }
  }, [user && user.id])

  useEffect(() => {
    if (user && menuId) {
      fetchDashboardPiece(menuId)
        .catch(err => {
          // do not display error
          console.log(err)
        }).then(() => {

        })
    }
  }, [menuId])

  return <Component {...props} />
}

const enhance = compose(
  withInternationalization(),
  connect(
    stateSelector,
    dispatch => ({
      fetchCustomUserPiece: async userId => {
        await dispatch(readEndpoint(`users/${userId}/relationships/customFieldsPiece`))
      },
      fetchDashboardPiece: async dashboardPieceId => {
        await dispatch(readEndpoint(`pieces/${dashboardPieceId}`))
      }
    })
  ),
  withCustomUserPiece
)

const enhancedComponent = enhance(DashboardComponent)

enhancedComponent.propTypes = {

}

enhancedComponent.defaultProps = {

}

export default enhancedComponent
