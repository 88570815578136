import React, { Component } from 'react'
import './Notifications.css'
import { Typography } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import WebPushFeeds from '../../containers/WebPushFeeds'
import PropTypes from 'prop-types'
import { List } from '@material-ui/core'
import { ListItem } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
import { Avatar } from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Image'
import WorkIcon from '@material-ui/icons/Work'
import RSSFeed from '@material-ui/icons/RssFeed'
import NotificationImportant from '@material-ui/icons/NotificationImportant'
import NotificationSender from '../NotificationSender/NotificationSender'

class Notifications extends Component {
  constructor (props) {
    super()
  }

  render () {
    const { classes, subscriberCount, feedsCount } = this.props

    return (
      <div className="Notifications">
        <Paper className={classes.paper}>
          <List>
            <ListItem>
              <Avatar>
                <NotificationImportant />
              </Avatar>
              <ListItemText primary="Subscribers" secondary={'' + subscriberCount} />
            </ListItem>
            <ListItem>
              <Avatar>
                <RSSFeed />
              </Avatar>
              <ListItemText primary="Feeds" secondary={'' + feedsCount} />
            </ListItem>
            <ListItem>
              <Avatar>
                <NotificationImportant />
              </Avatar>
              <ListItemText primary="Vacation" secondary="July 20, 2014" />
            </ListItem>
          </List>
        </Paper>
        <br />
        <Typography variant="h4">Web Push Feeds</Typography>
        <br />
        <WebPushFeeds clientId={this.props.clientId} />
        <br />
        <Typography variant="h4">Send Notification</Typography>
        <br />
        <NotificationSender clientId={this.props.clientId} />
      </div>
    )
  }
}

Notifications.propTypes = {
  clientId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  subscriberCount: PropTypes.number.isRequired,
  feedsCount: PropTypes.number.isRequired
}

export default Notifications
