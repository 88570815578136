import { AppBar } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Tab } from '@material-ui/core';
import { Tabs } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { querystringParse } from '../../lib/util/url';
import Errors from '../Errors/Errors';
import FullScreenLoader from '../FullScreenLoader/FullScreenLoader';
import Page from '../Page/Page';
import RawLink from '../RawLink/RawLink';
import styles from './Auth.module.css';

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      component="div"
      role="tabpanel"
      style={{ display: value === index ? 'block' : 'none' }}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

class Auth extends Component {
  constructor (props) {
    super()

    const {
      settings: {
        email,
        password
      }
    } = props

    this.state = {
      value: props.type === 'register' ? 1 : 0,
      email,
      password,
      recaptcha: null,
      username: '',
      wasSuccesfull: false,
      loading: false
    }
  }

    handleFormChange = name => event => {
      this.setState({
        [name]: event.target.value
      })
    };

    handleTabChange = (event, value) => {
      this.setState({ value })
      this.recaptchaReset()
    };

    handleTabChangeIndex = index => {
      this.setState({ value: index })
      this.recaptchaReset()
    };

    recaptchaReset () {
      if (!this.mounted) return
      this.recaptchaLoginRef && this.recaptchaLoginRef.reset()
      this.recaptchaRegisterRef && this.recaptchaRegisterRef.reset()
      this.setState({ recaptcha: null })
    }

    componentWillMount () {
      this.mounted = true
    }

    componentWillUnmount () {
      this.mounted = false
    }

    componentDidMount () {
      window.setTimeout(() => {
        this.recaptchaReset()
      }, 2000)
    }

    handleLoginSubmit = async e => {
      e.preventDefault()

      const { email, password, recaptcha } = this.state
      const { login } = this.props

      await login({ email, password, recaptcha })
      this.recaptchaReset()
    }

    handleRegisterSubmit = async e => {
      e.preventDefault()

      const { email, username, password, recaptcha } = this.state
      const { register } = this.props

      await register({ email, username, password, recaptcha })
      this.recaptchaReset()
    }

    handleRecaptchaChange = recaptcha => {
      this.setState({ recaptcha })
    }

    setLoginRecaptchaRef = ref => {
      this.recaptchaLoginRef = ref
    }

    setRegisterRecaptchaRef = ref => {
      this.recaptchaRegisterRef = ref
    }

    render () {
      const {
        classes,
        theme,
        location: { search },
        settings: {
          recaptchaSiteKey
        },
        auth: {
          isAuthenticating,
          isAuthenticated,
          userHasAuthenticated,
          recoverFromAuth,
          isLoading: authIsLoading,
          authData,
          errors: authErrors
        }
      } = this.props

      const errors = (authErrors || []).filter(err => !['no-jwt-token', 'jwt-invalid-token'].includes(get(err, 'code')))

      const { redirect } = querystringParse(search)

      const loading = this.state.loading || authIsLoading
      if (loading) return <FullScreenLoader />

      if (redirect && isAuthenticated) return <Redirect to={redirect} />

      return (
        <Page title="Sign in">
          <div className={styles.auth}>
            <Grid
              className={styles.fullHeight}
              container
              direction="row"
              justify="center"
              alignItems="center">
              <Paper className={styles.paper}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.value}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    varient="fullWidth"
                  >
                    <Tab label="Login" />
                    <Tab label="Register" />
                  </Tabs>
                </AppBar>
                <TabPanel index={0} value={this.state.value}>
                  <form onSubmit={this.handleLoginSubmit} className={classes.container} autoComplete="off">
                    <TextField
                      id="email"
                      label="Email"
                      className={styles.email}
                      value={this.state.email}
                      onChange={this.handleFormChange('email')}
                      margin="normal"
                      type="email"
                      variant="outlined"
                    />
                    <TextField
                      id="password"
                      label="Password"
                      className={styles.password}
                      value={this.state.password}
                      onChange={this.handleFormChange('password')}
                      margin="normal"
                      type="password"
                      variant="outlined"
                    />
                    <br />
                    <br />
                    <div style={{ minHeight: 100 }}>
                      <ReCAPTCHA ref={this.setLoginRecaptchaRef}
                        sitekey={recaptchaSiteKey}
                        onChange={this.handleRecaptchaChange}
                      />
                    </div>
                    <Errors errors={errors} />
                    <br />
                    {this.state.wasSuccesfull
                      ? <RawLink to={ROUTES.DASHBOARD}><Button varient="contained">To Dashboard</Button></RawLink>
                      : <Button variant="contained" type="submit">Login</Button>}
                    <Typography color="primary">{this.state.registerMsg}&nbsp;</Typography>
                  </form>
                </TabPanel>
                <TabPanel index={1} value={this.state.value}>
                  <form onSubmit={this.handleRegisterSubmit} className={classes.container} noValidate autoComplete="off">
                    <TextField
                      id="register-email"
                      label="Email"
                      className={styles.email}
                      value={this.state.email}
                      onChange={this.handleFormChange('email')}
                      margin="normal"
                      type="email"
                      variant="outlined"
                    />
                    <TextField
                      id="register-username"
                      label="Username"
                      className={styles.username}
                      value={this.state.username}
                      onChange={this.handleFormChange('username')}
                      margin="normal"
                      type="text"
                      variant="outlined"
                    />
                    <TextField
                      id="register-password"
                      label="Password"
                      className={styles.password}
                      value={this.state.password}
                      onChange={this.handleFormChange('password')}
                      margin="normal"
                      type="password"
                      variant="outlined"
                    /><br /><br />
                    <div style={{ minHeight: 100 }}>
                      <ReCAPTCHA ref={this.setRegisterRecaptchaRef}
                        sitekey={recaptchaSiteKey}
                        onChange={this.handleRecaptchaChange}
                      />
                    </div>
                    <Errors errors={errors} />
                    <br />
                    {this.state.wasSuccesfull
                      ? <RawLink to={ROUTES.DASHBOARD}><Button varient="contained">To Dashboard</Button></RawLink>
                      : <Button variant="contained" type="submit">Register</Button>}
                    <Typography color="primary">{this.state.registerMsg}&nbsp;</Typography>
                  </form>
                </TabPanel>
              </Paper>
            </Grid>
          </div>
        </Page>
      )
    }
}

Auth.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,

  login: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired
}

export default Auth
