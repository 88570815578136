import React, { Component } from 'react'
import styles from './NotificationSender.module.css'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { FormControlLabel } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { sendWebPushNotification } from '../../lib/notifications/broadcast'

const rmEmptyString = obj => Object.keys(obj).forEach(key => {
  if (obj[key] === '') delete obj[key]
})

class NotificationSender extends Component {
  constructor (props) {
    super()
    this.state = {
      data: {
        timestamp: Date.now(),
        image: '',
        body: '',
        title: '',
        requireInteraction: true,
        tag: Date.now(),
        data: {
          url: '',
          id: Date.now()
        }
      }
    }
  }

  componentWillMount () {
  }

  componentWillUnmount () {
  }

    handleSubmit = async e => {
      e.preventDefault()
      const data = this.state.data
      rmEmptyString(data)
      try {
        await sendWebPushNotification(this.props.clientId, data)
      } catch (err) {
        console.error(err)
      }
    }

    handleFormChange = name => e => {
      const data = { ...this.state.data }
      data[name] = e.target.value
      this.setState({ data })
    }

    handleFormCheckboxChange = name => e => {
      const data = { ...this.state.data }
      data[name] = e.target.checked
      this.setState({ data })
    }

    handleUrlChange = e => {
      const data = { ...this.state.data }
      data.data.url = e.target.value
      this.setState({ data })
    }

    render () {
      return (
        <div className="NotificationSender">
          <Paper className={styles.formWrapper}>
            <form onSubmit={this.handleSubmit} className={styles.form}>
              <TextField
                id="title"
                label="Title"
                required
                className={styles.title}
                value={this.state.data.title}
                onChange={this.handleFormChange('title')}
                margin="normal"
                type="text"
              />
              <FormControlLabel
                control={<Checkbox
                  checked={this.state.data.requireInteraction}
                  onChange={this.handleFormCheckboxChange('requireInteraction')} />
                }
                label="Require interaction"/>
              <TextField
                id="body"
                label="Body"
                required
                multiline
                rows="4"
                rowsMax="7"
                value={this.state.data.body}
                onChange={this.handleFormChange('body')}
                className={styles.body}
                margin="normal"
              />
              <TextField
                id="image"
                label="Image url"
                value={this.state.data.image}
                onChange={this.handleFormChange('image')}
                type="url"
                className={styles.image}
                margin="normal"
              />
              <TextField
                id="url"
                label="Url"
                value={this.state.data.data.url}
                onChange={this.handleUrlChange}
                className={styles.image}
                type="url"
                margin="normal"
              />
              <br /><br />
              <Button variant="contained" color="primary" type="submit">Submit</Button>
            </form>
          </Paper>
        </div>
      )
    }
}

NotificationSender.propTypes = {
  clientId: PropTypes.string.isRequired
}

export default NotificationSender
