
import React, { Component, useEffect, useState, forwardRef } from 'react';
import FullScreenError from '../components/FullScreenError/FullScreenError';
import FullScreenLoader from '../components/FullScreenLoader/FullScreenLoader';
import { errorToString, logError } from '../lib/util';

const cachedComponentsRegistryKeys = []
const cachedComponentsRegistryValues = []

const cachedPromisesRegistryKeys = []
const cachedPromisesRegistryValues = []

export default function withAsyncComponent(importComponent, loadingComponent = FullScreenLoader, errorComponent = FullScreenError) {
  const AsyncComponent = forwardRef((props, ref) => {
    const [{ component }, setComponent] = useState({ component: loadingComponent })
    const [error, setError] = useState()

    const load = async () => {
      if (component !== loadingComponent) return
      try {
        const index = cachedPromisesRegistryKeys.indexOf(importComponent)

        const promise = index >= 0
          ? cachedPromisesRegistryValues[index]
          : importComponent().then(val => console.log('import component', index) || val)

        if (index === -1) {
          cachedPromisesRegistryKeys.push(importComponent)
          cachedPromisesRegistryValues.push(promise)
        }

        const { default: component } = await promise

        cachedComponentsRegistryKeys.push(importComponent)
        cachedComponentsRegistryValues.push(component)

        setComponent({ component })
      } catch (err) {
        logError(err)
        setComponent({ component: errorComponent })
        setError(errorToString(err))
      }
    }

    useEffect(() => {
      load()
    }, [])

    const index = cachedComponentsRegistryKeys.indexOf(importComponent)

    console.log(index >= 0 ? 'Loaded async component from component registry cache' : 'Waiting for async component to download')

    const state = {
      component: index >= 0 ? cachedComponentsRegistryValues[index] : loadingComponent,
      error
    }

    const C = state.component

    return C ? <C ref={ref} error={state.error} message={state.error} text={state.error} {...props} /> : null
  })

  return AsyncComponent
}
