import { Typography } from '@material-ui/core'
import { ListItem } from '@material-ui/core'
import { ListItemIcon } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import propTypes from 'prop-types'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { compose } from 'redux'
import RawLink from '../../RawLink/RawLink'

const useStyles = makeStyles(theme => ({
  active: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  activeIcon: {
    color: theme.palette.primary.main
  }
}))

const NavItemComponent = ({ icon, typographyProps, color, to, text, disableLink, exact, ...rest }) => {
  const location = useLocation()
  const active = exact ? location.pathname === to : location.pathname.startsWith(to)
  const classes = useStyles()

  const listItem = (
    <ListItem button {...rest}>
      <ListItemIcon color={color} className={clsx(active && classes.activeIcon)}>{icon}</ListItemIcon>
      <ListItemText>
        <Typography {...typographyProps} className={clsx(typographyProps.className, active && classes.active)}>{text}</Typography>
      </ListItemText>
    </ListItem>
  )

  if (disableLink) {
    return listItem
  } else {
    return (
      <RawLink to={to}>{listItem}</RawLink>
    )
  }
}

NavItemComponent.propTypes = {
  icon: propTypes.object.isRequired,
  to: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  exact: propTypes.bool,
  disableLink: propTypes.bool,
  typographyProps: propTypes.object
}

NavItemComponent.defaultProps = {
  exact: false,
  disableLink: false,
  typographyProps: {}
}

const NavItem = NavItemComponent

export default NavItem
