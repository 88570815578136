import React, { Component, useState } from 'react'
import styles from './ToolsDraweritems.module.css'
import propTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { ListItem } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
import { Collapse } from '@material-ui/core'
import RawLink from '../../RawLink/RawLink'
import * as ROUTES from '../../../constants/routes'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import BuildIcon from '@material-ui/icons/Build'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import { ListItemIcon } from '@material-ui/core'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import SupervisorAccountIconOutline from '@material-ui/icons/SupervisorAccountOutlined'
import AdministrationDrawerItems from '../../../containers/menus/AdministrationDrawerItems'
import get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import NavItem from '../NavItem/NavItem'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import ImageIcon from '@material-ui/icons/Image'

const useStyles = makeStyles(theme => ({
  leftPad: {
    paddingLeft: theme.spacing(2)
  }
}))

let expanded = false
let _expandedSubItems = {}

const items = [
  {
    key: 'snippets',
    route: ROUTES.TOOLS_SNIPPETS,
    icon: <BuildIcon />,
    subItems: [
      {
        key: 'googleMapsSnippet',
        route: ROUTES.TOOLS_SNIPPETS_GOOGLE_MAPS_HTML,
        icon: <GpsFixedIcon />
      }
    ]
  }
]

const ToolsDrawerItems = props => {
  const [show, setShow] = useState(expanded)
  expanded = show
  const [expandedSubItems, setExpandedSubItems] = useState(_expandedSubItems)
  _expandedSubItems = expandedSubItems

  const { user, messages } = props

  const toggleTools = () => setShow(!show)

  const classes = useStyles()

  return (
    <div className="ToolsDrawerItems">
      <NavItem onClick={toggleTools} disableLink to="/tools" text={get(messages, 'tools.message', 'Tools')} icon={<AccountTreeIcon />} />
      <div className={classes.leftPad}>
        <Collapse key="Tools items" component="li" in={show} timeout="auto" unmountOnExit>
          <NavItem
            exact
            icon={<SupervisorAccountIcon />}
            text={get(messages, 'toolsOverview.message', 'Tools Overview')}
            to={'/tools'} />

          {items.map(item => {
            return (
              <div key={item.key}>
                <NavItem
                  onClick={() => setExpandedSubItems({ ...expandedSubItems, [item.key]: !expandedSubItems[item.key] })}
                  disableLink={!!item.subItems}
                  icon={item.icon}
                  text={get(messages, [item.key, 'message'])}
                  to={item.route} />

                <Collapse key={item.key + ' collapse'} component="li" in={expandedSubItems[item.key]} timeout="auto" unmountOnExit>
                  <div className={classes.leftPad}>
                    {item.subItems.map(subItem => {
                      return (
                        <NavItem
                          exact
                          key={subItem.key}
                          icon={subItem.icon}
                          text={get(messages, [subItem.key, 'message'])}
                          to={subItem.route} />
                      )
                    })}
                  </div>
                </Collapse>
              </div>
            )
          })}

        </Collapse>
      </div>
    </div>
  )
}

ToolsDrawerItems.propTypes = {
}

export default ToolsDrawerItems
